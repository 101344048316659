import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';

import { auth } from '../../../services/firebase';

import NumberFormat from 'react-number-format';

import BootstrapTable from 'react-bootstrap-table-next';

import BusinessExpenseForm from './BusinessExpenseForm';

class BusinessExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      isExpenseDetailPaneOpen: false,
      expensesList: this.props.expensesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleAddNewExpenseButton = (event) => {
    let currentExpenseItem = [];
    currentExpenseItem['key'] = '';
    this.setState({
      currentExpenseItem,
      isNewExpense: true,
      isExpenseDetailPaneOpen: true,
    });
  };

  handleExpenseSaveDeleteButtonClick = (value) => {
    this.setState({ isExpenseDetailPaneOpen: false });
  };

  render() {
    let expensesList = [];
    let tempList = this.props.expensesList;

    tempList.forEach((expense) => {
      const itemTotal =
        parseFloat(expense['amount']) * parseFloat(expense['num_times']);

      expense['daily'] = itemTotal / 365;
      expense['weekly'] = itemTotal / 52;
      expense['fortnightly'] = itemTotal / 26;
      expense['monthly'] = itemTotal / 12;
      expense['quarterly'] = itemTotal / 4;
      expense['yearly'] = itemTotal;
      expensesList.push(expense);
    });

    const columns = [
      {
        dataField: 'expense_name',
        text: 'Expense',
        sort: true,
        footer: 'TOTALS',
      },

      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: '',
      },
      {
        dataField: 'num_times',
        text: 'Qty',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footer: '',
      },
      {
        dataField: 'daily',
        text: 'Day',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 365;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'weekly',
        text: 'Week',
        sort: true,
        isDummyField: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 52;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'fortnightly',
        text: 'FN',
        sort: true,
        isDummyField: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 26;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'monthly',
        text: 'Mon',
        sort: true,
        isDummyField: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 12;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'quarterly',
        text: 'Qtr',
        sort: true,
        isDummyField: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 4;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'yearly',
        text: 'Year',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        isDummyField: true,
        formatter: (cell, row) => {
          let calcTotal = row.amount * row.num_times;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'expense_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentExpenseItem: row,
          isNewExpense: false,
          isExpenseDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div>
        <div className='d-flex'>
          <div
            onClick={() => this.handleAddNewExpenseButton()}
            className='btn btn-outline-dark ml-auto'>
            <span>Add New Expense</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no expenses to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={expensesList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='expense-detail-pane'
          overlayClassName='pane-expense-sliding-pane'
          isOpen={this.state.isExpenseDetailPaneOpen}
          title='Expense Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isExpenseDetailPaneOpen: false });
          }}>
          <BusinessExpenseForm
            linkedEntityKeys={this.props.linkedEntityKeys}
            currentExpenseItem={this.state.currentExpenseItem}
            isNewExpense={this.state.isNewExpense}
            currentUserId={auth().currentUser.uid}
            onExpenseSaveDeleteButtonClicked={() =>
              this.handleExpenseSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default BusinessExpenses;
