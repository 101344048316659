import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Risk Management and Compliance</strong> Strategy
        </h4>
        This section is currently being developed.
        <br />
        It will be about goals you want to achieve and what activities you can
        put in place to understand and mitigate key risks identified for your
        business. The key questions to ask are:
        <br />
        -> What can go wrong with your business/industry?
        <br />
        -> What is the likelihoodof that going wrong? <br />
        -> What is the plan to work around that if it does happen?
        <br />
        -> What compliance requirements are there? <br />
        -> What goals do you want to achievearound this?
        <br />
      </div>
    );
  }
}

export default Sales;
