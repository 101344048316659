import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import Login from './pages/Login';
import BizStrategy from './pages/BizStrategy';
import PersonalStrategy from './pages/PersonalStrategy';
import { auth, db } from './services/firebase';
import { getFirestoreTimestamp } from './helpers/db';
import './styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import {
  userProfile,
  businessDetail,
  marketingOverview,
} from './helpers/dataObjects';
import ClientManager from './pages/ClientManager';
import AccountDetails from './pages/AccountDetails';
import ProfileManager from './pages/ProfileManager';

const firestoreTimeStamp = getFirestoreTimestamp();

async function createNewBlankBusinesses(dashboardId) {
  const newBusiness = {
    ...businessDetail,
    created: firestoreTimeStamp,
  };

  for (var i = 0; i < 3; i++) {
    await db
      .collection('dashboards')
      .doc(dashboardId)
      .collection('businesses')
      .add(newBusiness)
      .then(function (newBusinessRef) {
        const businessId = newBusinessRef.id;
        /* create marketing overview subcollection */
        const newMarketingOverview = {
          ...marketingOverview,
          created: firestoreTimeStamp,
        };
        db.collection('dashboards')
          .doc(dashboardId)
          .collection('businesses')
          .doc(businessId)
          .collection('marketing')
          .add(newMarketingOverview);

        //TODO      Remove adding the businesses at top level collection -

        /* db.collection('businesses').add({
          business_id: businessId,
          dashboard_id: dashboardId,
          created: firestoreTimeStamp,
        }); */
      });
  }
}

async function getProfileDashboardForCurrentUser(user) {
  let response = {
    profile_id: 'new',
    dashboard_id: '',
    profile_complete: '',
  };
  await db
    .collection('profiles')
    .where('uid', '==', user.uid)
    .get()
    .then((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        primary_dashboard_id: doc.data().primary_dashboard_id,
      }));

      if (data && data.length > 0) {
        /* profile exists */
        response['profile_id'] = data[0].id;
        response['dashboard_id'] = data[0].primary_dashboard_id;
        response['profile_complete'] = data[0].profile_complete;
      }
    });

  return response;
}

async function createNewDashboardId(profileId) {
  /* create new profile  */
  let dashboardId = '';

  const newDashboard = {
    primary_profile_id: profileId,
    created: firestoreTimeStamp,
    user_display_name: auth().currentUser.displayName,
    uid: auth().currentUser.uid,
  };

  await db
    .collection('dashboards')
    .add(newDashboard)
    .then(function (newDashboardRef) {
      dashboardId = newDashboardRef.id;
      db.collection('profiles').doc(profileId).update({
        primary_dashboard_id: dashboardId,
      });
      db.collection('dashboards').doc(dashboardId).collection('personal').add({
        profile_id: profileId,
        created: firestoreTimeStamp,
        uid: auth().currentUser.uid,
      });
    });

  return dashboardId;
}

async function createNewProfileId(user) {
  /* create new profile  */
  let profileId = '';

  const newProfile = {
    ...userProfile,
    uid: user.uid ? user.uid : '',
    display_name: user.displayName ? user.displayName : '',
    email_address: user.email ? user.email : '',
    mobile_phone: user.phoneNumber ? user.phoneNumber : '',
    created: firestoreTimeStamp,
  };

  await db
    .collection('profiles')
    .add(newProfile)
    .then(function (newUserRef) {
      profileId = newUserRef.id;
    });

  return profileId;
}

function PrivateRoute({
  component: Component,
  authenticated,
  currentUserDetails,
  dashboardId,
  clientDashboardId,
  onLoadNewDashboard,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component
            {...props}
            currentUserDetails={currentUserDetails}
            dashboardId={dashboardId}
            clientDashboardId={clientDashboardId}
            onLoadNewDashboard={onLoadNewDashboard}
          />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  let locationPath = useLocation();

  let locationPathname = '/dashboard';
  if (locationPath.state) {
    locationPathname = locationPath.state.from.pathname;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={locationPathname} />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
      loadingModule: 'App',
      currentUserDetails: {},
      dashboardData: {},
    };
  }

  addCurrentUserProfileSnapshot = (profileId) => {
    const pathArray = window.location.pathname.split('/');

    db.collection('profiles')
      .doc(profileId)
      .onSnapshot((snapshot) => {
        let data = snapshot.data();
        data['id'] = snapshot.id;

        if (!data['profile_complete'] && pathArray[1] !== 'account') {
          window.location = '/account';
        }

        this.setState({
          currentUserDetails: data,
          dashboardId: data.primary_dashboard_id,
          authenticated: true,
          loading: false,
        });
      });
  };

  addCollectionSnapshot = (dbRef, collection) => {
    /* add snapshot data then set state */
    let setStateData = [];

    dbRef.collection(collection).onSnapshot((snapshot) => {
      let snapshotData = [];

      snapshot.forEach((doc) => {
        snapshotData = doc.data();
        snapshotData['key'] = doc.id;
        setStateData.push(snapshotData);
      });

      const dashboardData = {
        ...this.state.dashboardData,
        [collection]: setStateData,
      };

      this.setState({ dashboardData, loading: true });
    });
    return true;
  };

  async componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        const loadUserProfile = async (user) => {
          const profileDashboardIds = await getProfileDashboardForCurrentUser(
            user
          );

          if (profileDashboardIds.profile_id === 'new') {
            /* create new profile, dashboard and relevant subcollections */
            profileDashboardIds.profile_id = await createNewProfileId(user);
            const dashboardId = await createNewDashboardId(
              profileDashboardIds.profile_id
            );
            await createNewBlankBusinesses(dashboardId);
          } else {
            /* add snapshots for existing dashboard and subcollections */
            /*          const dashboardColRef = db
              .collection('dashboards')
              .doc(profileDashboardIds.dashboard_id);

            this.addCollectionDataSetState(dashboardColRef, 'activities');
            this.addCollectionDataSetState(dashboardColRef, 'goals');
            this.addCollectionDataSetState(dashboardColRef, 'businesses'); */
          }

          this.addCurrentUserProfileSnapshot(profileDashboardIds.profile_id);
        };
        loadUserProfile(user);
      } else {
        this.setState({ authenticated: false, loading: false });
      }
    });
  }
  handleLoadNewDashboard = (clientDashboardId) => {
    this.setState({ clientDashboardId });
    window.location = '/dashboard/' + clientDashboardId;
  };

  render() {
    return this.state.loading === true ? (
      <React.Fragment>
        <div className='loading-text'>Loading...</div>
        <div
          className='spinner-border text-success loading-indicator'
          role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </React.Fragment>
    ) : (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />

          <PrivateRoute
            path='/account'
            authenticated={this.state.authenticated}
            component={AccountDetails}
            currentUserDetails={this.state.currentUserDetails}
          />

          <PrivateRoute
            path='/biz-strategy'
            authenticated={this.state.authenticated}
            component={BizStrategy}
            currentUserDetails={this.state.currentUserDetails}
          />

          <PrivateRoute
            path='/client-manager'
            authenticated={this.state.authenticated}
            component={ClientManager}
            currentUserDetails={this.state.currentUserDetails}
            onLoadNewDashboard={this.handleLoadNewDashboard}
          />
          <PrivateRoute
            path='/dashboard'
            authenticated={this.state.authenticated}
            component={Dashboard}
            currentUserDetails={this.state.currentUserDetails}
            dashboardId={this.state.dashboardId}
            clientDashboardId={this.state.clientDashboardId}
          />

          <PublicRoute
            path='/login'
            authenticated={this.state.authenticated}
            component={Login}
          />

          <PrivateRoute
            path='/personal-strategy'
            authenticated={this.state.authenticated}
            component={PersonalStrategy}
            currentUserDetails={this.state.currentUserDetails}
          />

          <PrivateRoute
            path='/profile-manager'
            authenticated={this.state.authenticated}
            component={ProfileManager}
            currentUserDetails={this.state.currentUserDetails}
            onLoadNewDashboard={this.handleLoadNewDashboard}
          />

          <PublicRoute
            path='/signup'
            authenticated={this.state.authenticated}
            component={Signup}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
