import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import ProfileDetailForm from '../../profile/ProfileDetailForm';
import { Table } from 'react-bootstrap';

import { auth } from '../../../services/firebase';

class PersonalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      isProfileDetailsPaneOpen: false,
      linkedEntityKeys: this.props.linkedEntityKeys,
    };
  }

  handleEditPersonalDetailsButtonClick = () => {
    this.setState({
      isProfileDetailsPaneOpen: true,
    });
  };

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <div className='d-flex'>
          <h4>Personal Strategy Summary</h4>
          <div
            className='ml-auto mr-5 float-right btn btn-success'
            onClick={() => this.handleEditPersonalDetailsButtonClick()}>
            Edit Personal Details
          </div>
        </div>
        <div className='table-responsive-sm'>
          <Table className='table d-flex'>
            <tbody>
              <tr className=''>
                <td>Review Date</td>
                <td>No review date set</td>
              </tr>
              <tr>
                <td>Total Goals:</td>
                <td>{this.props.totalGoals}</td>
              </tr>
              <tr>
                <td>Total Activities Logged:</td>
                <td>{this.props.totalActivities}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <SlidingPane
          className='profile-details-pane'
          overlayClassName='pane-details-sliding-pane'
          isOpen={this.state.isProfileDetailsPaneOpen}
          title='Personal Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isProfileDetailsPaneOpen: false });
          }}>
          <ProfileDetailForm
            currentDashboardId={this.props.linkedEntityKeys['dashboard_id']}
            currentUserId={auth().currentUser.uid}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default PersonalSummary;
