import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signin, signInWithGoogle } from '../helpers/auth';

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
    try {
      await signin(this.state.email, this.state.password);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      await signInWithGoogle();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    return (
      <div className='container'>
        <form
          className='mt-5 py-5 px-5'
          autoComplete='off'
          onSubmit={this.handleSubmit}>
          <h3>Login to the Brilliant Business Strategy Portal</h3>
          <p className='lead'>
            Enter your email address and password to login.
          </p>
          <div className='form-group'>
            <input
              className='form-control'
              placeholder='Email'
              name='email'
              type='email'
              onChange={this.handleChange}
              value={this.state.email}
            />
          </div>
          <div className='form-group'>
            <input
              className='form-control'
              placeholder='Password'
              name='password'
              onChange={this.handleChange}
              value={this.state.password}
              type='password'
            />
          </div>
          <div className='form-group'>
            {this.state.error ? (
              <p className='text-danger'>{this.state.error}</p>
            ) : null}
            <button className='btn btn-primary px-5' type='submit'>
              Login
            </button>
            &nbsp;&nbsp;
            <Link className='btn px-5' to='/'>
              Cancel
            </Link>
          </div>
          <p>You can also log in with any of these services:</p>
          <button
            className='btn btn-danger mr-2'
            type='button'
            onClick={this.googleSignIn}>
            Sign in with Google
          </button>
          <hr />
          <p>
            Don't have an account? <Link to='/signup'>Sign up</Link>
          </p>
        </form>
      </div>
    );
  }
}
