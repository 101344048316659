import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';

import { auth } from '../../../services/firebase';
import NumberFormat from 'react-number-format';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ProductServiceForm from './ProductServiceForm';

//const { ToggleList } = ColumnToggle;

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div className='d-flex ml-auto mt-3'>
    <div
      className='btn-group btn-group-toggle small ml-auto grossProfit-selector d-flex'
      data-toggle='buttons'>
      <div className='mx-1'>
        Gross Profit
        <br /> Columns
      </div>
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) =>
          column.dataField.includes('_gp') ? (
            <div key={'gp' + column.dataField}>
              <input
                type='checkbox'
                id={column.dataField}
                key={'inputgp' + column.dataField}
                className='weekday '
                data-toggle='button'
                checked={column.toggle}
                onChange={() => onColumnToggle(column.dataField)}
              />

              <label htmlFor={column.dataField}>{column.text}</label>
            </div>
          ) : (
            ''
          )
        )}
    </div>
    <div
      className='btn-group btn-group-toggle small ml-auto d-flex breakeven-selector d-flex'
      data-toggle='buttons'>
      <div className='mx-1'>
        Breakeven
        <br />
        Columns
      </div>
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) =>
          column.dataField.includes('_be') ? (
            <div key={'be' + column.dataField}>
              <input
                type='checkbox'
                id={column.dataField}
                key={'inputbe' + column.dataField}
                className='weekday '
                data-toggle='button'
                checked={column.toggle}
                onChange={() => onColumnToggle(column.dataField)}
              />

              <label htmlFor={column.dataField}>{column.text}</label>
            </div>
          ) : (
            ''
          )
        )}
    </div>
  </div>
);

class ProductsServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      isProductServiceDetailPaneOpen: false,
      productsServicesList: this.props.productsServicesList,
      loadingProductsServicesList: true,

      linkedEntityKeys: this.props.linkedEntityKeys,
      grossProfitStyle: {
        backgroundColor: '#28a745',
        color: '#ffffff',
      },
      breakevenStyle: {
        backgroundColor: '#ffc107',
      },
    };
  }

  useEffect = () => {};

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
    this.setState({ loadingProductsServicesList: false });
  }

  handleAddNewProductServiceButton = (event) => {
    let currentProductServiceItem = [];
    currentProductServiceItem['key'] = '';
    this.setState({
      currentProductServiceItem,
      isNewProductService: true,
      isProductServiceDetailPaneOpen: true,
    });
  };

  handleProductServiceSaveDeleteButtonClick = (value) => {
    this.setState({ isProductServiceDetailPaneOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'product_service_name',
        text: 'Product/Service',
        sort: true,
        hidden: false,
        footer: 'TOTALS',
      },

      {
        dataField: 'sell_price',
        text: 'Sell',
        sort: true,
        hidden: false,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'cost_price',
        text: 'Cost',
        sort: true,
        hidden: false,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'num_times',
        text: 'Qty',
        sort: false,
        hidden: false,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footer: '',
      },
      {
        dataField: 'daily_gp',
        text: 'Day',
        sort: true,
        hidden: true,
        style: this.state.grossProfitStyle,
        align: 'right',
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'daily_be',
        text: 'Day',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'daily_behrs',
        text: 'Hrs (D)',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'weekly_gp',
        text: 'Week',
        sort: true,
        hidden: true,
        style: this.state.grossProfitStyle,
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'weekly_be',
        text: 'Week',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'weekly_behrs',
        text: 'Hrs (W)',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'fortnightly_gp',
        text: 'FN',
        sort: true,
        hidden: true,
        style: this.state.grossProfitStyle,
        align: 'right',
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'fortnightly_be',
        text: 'FN',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'fortnightly_behrs',
        text: 'Hrs (FN)',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'monthly_gp',
        text: 'Mon',
        sort: true,
        hidden: true,
        style: this.state.grossProfitStyle,
        align: 'right',
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'monthly_be',
        text: 'Mon',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'monthly_behrs',
        text: 'Hrs (M)',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'quarterly_gp',
        text: 'Qtr',
        sort: true,
        hidden: true,
        style: this.state.grossProfitStyle,
        align: 'right',
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'quarterly_be',
        text: 'Qtr',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'quarterly_behrs',
        text: 'Hrs (Q)',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'yearly_gp',
        text: 'Year',
        sort: true,
        hidden: false,
        style: this.state.grossProfitStyle,
        align: 'right',
        headerStyle: this.state.grossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'yearly_be',
        text: 'Year',
        sort: true,
        hidden: false,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
      {
        dataField: 'yearly_behrs',
        text: 'Hrs (Y)',
        sort: true,
        hidden: false,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'product_service_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentProductServiceItem: row,
          isNewProductService: false,
          isProductServiceDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div
        className='col products-services-section'
        ref={this.productsServicesAreaRef}>
        {this.state.loadingProductsServicesList ? (
          <div
            className='spinner-border text-success loading-indicator'
            role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          <div>
            <div className='d-flex'>
              <div
                onClick={() => this.handleAddNewProductServiceButton()}
                className='btn btn-outline-dark ml-auto'>
                <span>Add New Product/Service</span>
              </div>
            </div>

            <ToolkitProvider
              keyField='key'
              data={this.props.productsServicesList}
              columns={columns}
              defaultSorted={defaultSorted}
              columnToggle>
              {(props) => (
                <div>
                  <CustomToggleList {...props.columnToggleProps} />

                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    bordered={false}
                    rowEvents={rowEvents}
                    noDataIndication='There are no products or services to display here at the moment'
                    classes='auto-table-layout'
                  />
                </div>
              )}
            </ToolkitProvider>

            <SlidingPane
              className='product-service-detail-pane'
              overlayClassName='pane-product-service-sliding-pane'
              isOpen={this.state.isProductServiceDetailPaneOpen}
              title='Product/Service Detail'
              subtitle=''
              width='85%'
              from='right'
              onRequestClose={() => {
                this.setState({ isProductServiceDetailPaneOpen: false });
              }}>
              <ProductServiceForm
                totalGrossProfitNeeded={this.props.totalGrossProfitNeeded}
                linkedEntityKeys={this.props.linkedEntityKeys}
                currentProductServiceItem={this.state.currentProductServiceItem}
                isNewProductService={this.state.isNewProductService}
                currentUserId={auth().currentUser.uid}
                onProductServiceSaveDeleteButtonClicked={() =>
                  this.handleProductServiceSaveDeleteButtonClick()
                }
              />
            </SlidingPane>
          </div>
        )}
      </div>
    );
  }
}

export default ProductsServices;
