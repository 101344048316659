import { db } from '../services/firebase';

export function readChats() {
  let abc = [];
  db.ref('chats').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeChats(message) {
  return db.ref('chats').push({
    content: message.content,
    timestamp: message.timestamp,
    uid: message.uid,
  });
}

export function readProfiles() {
  let abc = [];
  db.ref('profiles').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeProfile(profile) {
  return db.ref('profiles').push({
    first_name: profile.first_name,
    uid: profile.uid,
  });
}

export function getFirestoreTimestamp() {
  return db.md.firebase_.firestore.Timestamp.now();
}

export const unSubSnapshot = {};

export async function addCollectionSnapshot(dbRef, collection, stateData) {
  /* add snapshot data then set state */

  try {
    return new Promise((resolve, reject) => {
      unSubSnapshot[collection] = dbRef
        .collection(collection)
        .onSnapshot((snapshot) => {
          let setStateData = [];

          snapshot.forEach((doc) => {
            let snapshotData = [];
            snapshotData = doc.data();
            snapshotData['key'] = doc.id;
            setStateData.push(snapshotData);
          });

          stateData[collection] = setStateData;

          resolve(stateData);
        });
    });
  } catch (error) {
    resolve(error);
  }
}

export async function addDocumentSnapshot(dbRef, docId, collection, stateData) {
  /* add snapshot data then set state */

  try {
    return new Promise((resolve, reject) => {
      unSubSnapshot[docId] = dbRef.doc(docId).onSnapshot((doc) => {
        stateData[collection] = {
          key: docId,
          data: doc.data(),
        };

        resolve(stateData);
      });
    });
  } catch (error) {
    resolve(error);
  }
}
