export function formatDate(expo_date) {
  const d = new Date(expo_date);
  const format_date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  return format_date;
}

export function formatDateYYYYMMDD(inputDate) {
  const d = new Date(inputDate);
  const format_date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  return format_date;
}

export function getCurrentDate(separator = '') {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export function stringToBoolean(string) {
  if (typeof string === 'boolean') {
    return string;
  } else {
    switch (string.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }
}

export function getDaysOfWeek() {
  let daysOfWeekObject = [
    {
      day_name: 'Sunday',
      short_name: 'Sun',
      obj_name: 'sun',
      first_day_of_week: true,
    },
    {
      day_name: 'Monday',
      short_name: 'Mon',
      obj_name: 'mon',
      first_day_of_week: false,
    },
    {
      day_name: 'Tuesday',
      short_name: 'Tue',
      obj_name: 'tue',
      first_day_of_week: false,
    },
    {
      day_name: 'Wednesday',
      short_name: 'Wed',
      obj_name: 'wed',
      first_day_of_week: false,
    },
    {
      day_name: 'Thursday',
      short_name: 'Thu',
      obj_name: 'thu',
      first_day_of_week: false,
    },
    {
      day_name: 'Friday',
      short_name: 'Fri',
      obj_name: 'fri',
      first_day_of_week: false,
    },
    {
      day_name: 'Saturday',
      short_name: 'Sat',
      obj_name: 'sat',
      first_day_of_week: false,
    },
  ];

  return daysOfWeekObject;
}

export const copyDoc = async (
  collectionFromRef,
  docId,
  collectionToRef,
  addData,
  recursive
) => {
  // document reference
  const docRef = collectionFromRef.doc(docId);

  // copy the document
  const docData = await docRef
    .get()
    .then((doc) => doc.exists && doc.data())
    .catch((error) => {
      console.error('Error reading document', JSON.stringify(error));
    });

  if (docData) {
    // document exists, create the new item
    await collectionToRef
      .doc(docId)
      .set({ ...docData, ...addData })
      .catch((error) => {
        console.error('Error creating document', JSON.stringify(error));
      });

    if (recursive) {
      // subcollections - listCollections does not work in Web App

      const subcollections = await docRef.listCollections();
      for await (const subcollectionRef of subcollections) {
        const subcollectionPath = `${collectionFromRef.path}/${subcollectionRef.id}`;
        console.log(subcollectionPath);
        // get all the documents in the collection
        return await subcollectionRef
          .get()
          .then(async (snapshot) => {
            const docs = snapshot.docs;

            console.log(docs);
            for await (const doc of docs) {
              await copyDoc(
                subcollectionPath,
                doc.id,
                `${collectionToRef.path}/${docId}/${subcollectionRef.id}`,
                true
              );
            }
            return true;
          })
          .catch((error) => {
            console.error(
              'Error reading subcollection',
              subcollectionPath,
              JSON.stringify(error)
            );
          });
      }
    }
    return true;
  }
  return false;
};
