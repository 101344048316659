import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../images/brilliantsuccess-logo.png';

import { auth } from '../../services/firebase';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {
    return (
      <header>
        <Navbar
          className='header w-100'
          collapseOnSelect
          expand='lg'
          variant='dark'>
          <Navbar.Brand href='/dashboard'>
            <img
              className='logo-image'
              src={logo}
              alt='Brilliant Success.biz Logo'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto'>
              {auth().currentUser ? (
                <div className='navbar-nav'>
                  {this.props.currentUserDetails &&
                  this.props.currentUserDetails.is_profile_manager ? (
                    <Nav.Link
                      className='nav-item btn btn-outline-secondary mr-3'
                      href='/profile-manager'>
                      Profile Manager
                    </Nav.Link>
                  ) : (
                    ''
                  )}
                  {this.props.currentUserDetails &&
                  this.props.currentUserDetails.is_advisor ? (
                    <Nav.Link
                      className='nav-item btn btn-outline-secondary mr-3'
                      href='/client-manager'>
                      Client Manager
                    </Nav.Link>
                  ) : (
                    ''
                  )}
                  {this.props.currentUserDetails ? (
                    <Nav.Link
                      className='nav-item btn btn-outline-secondary mr-3'
                      href='/account'>
                      My Account
                    </Nav.Link>
                  ) : (
                    ''
                  )}
                  <Nav.Link
                    className='nav-item btn btn-outline-secondary mr-3'
                    href='/dashboard'>
                    My Dashboard
                  </Nav.Link>
                  <Nav.Link
                    className='nav-item btn btn-outline-secondary mr-3'
                    href='/'
                    onClick={() => auth().signOut()}>
                    Logout
                  </Nav.Link>
                </div>
              ) : (
                <div className='navbar-nav'>
                  <Nav.Link className='nav-item nav-link mr-3' href='/login'>
                    Sign In
                  </Nav.Link>
                  <Nav.Link className='nav-item nav-link mr-3' href='/signup'>
                    Sign Up
                  </Nav.Link>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
