import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import dayjs from 'dayjs';

import { auth } from '../../services/firebase';

class Clients extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  async componentWillUnmount() {}

  handleLoadNewDashboard = (dashboardId) => {
    this.props.onLoadNewDashboard(dashboardId);
  };

  render() {
    const columns = [
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'next_meeting',
        text: 'Next Meeting',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },

      {
        dataField: 'dashboard_view',
        text: 'Dashboard',
        sort: true,
        isDummyField: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            console.log(row.primary_dashboard_id);
            this.props.onLoadNewDashboard(row.primary_dashboard_id);
          },
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div key={rowIndex} className='btn btn-sml btn-outline-dark'>
              Dashboard
            </div>
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'display_name',
        order: 'asc',
      },
    ];

    return (
      <div>
        Clients page: <br />
        <BootstrapTable
          noDataIndication='There are no clients to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={this.props.clientsList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
        />
      </div>
    );
  }
}
export default Clients;
