import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

//import ExposList from '../components/ExposList';
import BusinessDetailForm from '../components/strategy/business/BusinessDetailForm';
import Activities from '../components/strategy/activities/Activities';
import Goals from '../components/strategy/goals/Goals';

import SlidingPane from 'react-sliding-pane';

import { auth } from '../services/firebase';
import { db } from '../services/firebase';

let unSubSnapshot = {};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      dashboardId: this.props.dashboardId,

      isBusinessDetailPaneOpen: false,
      isGoalDetailPaneOpen: false,
      isActivityDetailPaneOpen: false,
      isAllSnapshotsAdded: false,
    };

    //  this.dashboardAreaRef = React.createRef();
    //  this.businessesAreaRef = React.createRef();
    //  this.goalsAreaRef = React.createRef();
    //  this.activitiesAreaRef = React.createRef();
  }

  async addCollectionSnapshot(dbRef, collection) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[collection] = dbRef
          .collection(collection)
          .onSnapshot((snapshot) => {
            let setStateData = [];
            let dashboardData = this.state.dashboardData;

            snapshot.forEach((doc) => {
              let snapshotData = [];
              snapshotData = doc.data();
              snapshotData['key'] = doc.id;
              setStateData.push(snapshotData);
            });

            dashboardData[collection] = setStateData;

            this.setState({ dashboardData });
            resolve(true);
          });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async addDocumentSnapshot(dbRef, docId) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[docId] = dbRef.doc(docId).onSnapshot((doc) => {
          const dashboardData = {
            ...this.state.dashboardData,
            [docId]: doc.data(),
          };

          this.setState({ dashboardData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  handleDashboardBusinessButtonClick = (businessDetail) => {
    if (businessDetail.status === 'new') {
      this.setState({
        selectedBusinessDetail: businessDetail,
        isBusinessDetailPaneOpen: true,
      });
    } else {
      window.location =
        '/biz-strategy/' + this.state.dashboardId + '/' + businessDetail.key;
    }
  };

  handleDashboardPersonalButtonClick = () => {
    window.location = '/personal-strategy/' + this.state.dashboardId;
  };

  async componentDidMount() {
    const pathArray = window.location.pathname.split('/');
    const clientDashboardId = pathArray[2];

    let dashboardId = '';

    if (clientDashboardId) {
      dashboardId = clientDashboardId;
    } else {
      dashboardId = this.props.dashboardId;
    }
    this.setState({ dashboardId });

    const dashboardCollRef = db.collection('dashboards');
    const dashboardDocRef = dashboardCollRef.doc(dashboardId);

    this.setState({
      isDataLoadCompleted: true,
    });

    try {
      Promise.all([
        this.addDocumentSnapshot(dashboardCollRef, dashboardId),
        this.addCollectionSnapshot(dashboardDocRef, 'businesses'),
        this.addCollectionSnapshot(dashboardDocRef, 'goals'),
        this.addCollectionSnapshot(dashboardDocRef, 'activities'),
        this.addCollectionSnapshot(dashboardDocRef, 'personal'),
      ]).then((result) => {
        this.setState({ isAllSnapshotsAdded: true });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  render() {
    /*  process any specific data transforms etc */
    const dashboardData = this.state.dashboardData;
    const dashboardId = this.state.dashboardId;

    let linkToOptions = [];
    let linkedEntityKeys = {};
    let dashboardGoalsList = [];
    let dashboardActivitiesList = [];

    let isDataLoadCompleted = false;

    if (this.state.dashboardData && this.state.isAllSnapshotsAdded) {
      isDataLoadCompleted = true;

      /* linkedEntityKeys */
      linkedEntityKeys = {
        source: 'dashboard',
        personal_key: dashboardData.personal.key,
        dashboard_id: dashboardId,
        linked_entity_id: '',
      };

      /* linkToOptions   */
      dashboardData.businesses.forEach((businessItem) => {
        linkToOptions.push({
          key: businessItem.key,
          name: businessItem.business_name,
        });
      });
      linkToOptions.push({
        key: dashboardData.personal.key,
        name: 'Personal',
      });

      /* filter GOALS list to dashboard display */
      dashboardGoalsList = dashboardData.goals.filter(
        (goalItem) => goalItem.dashboard_display
      );

      /* filter ACTIVITIES list to dashboard display  */
      dashboardActivitiesList = dashboardData.activities.filter(
        (activityItem) => activityItem.dashboard_display
      );
    } /* end if state isDataLoadCompleted = true */

    return (
      <div>
        <Header
          dashboardId={this.state.dashboardId}
          currentUserDetails={this.props.currentUserDetails}
        />
        <div className='row mt-1 py-3 px-3'>
          <React.Fragment>
            {/* start dashboard area */}
            <div
              className='col dashboard-section global-background'
              ref={this.dashboardAreaRef}>
              {/* isDataLoadCompleted indicator */}
              {!isDataLoadCompleted ? (
                <div
                  className='spinner-border text-success isDataLoadCompleted-indicator'
                  role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                <div>
                  <h2>Brilliant Business Strategy Dashboard</h2>
                  Welcome {this.props.currentUserDetails.display_name},<br />
                  <p>
                    Select one of the business strategy buttons below to add a
                    new business detail or to enter into the strategy for that
                    business.
                  </p>
                  <div
                    className='col businesses-section'
                    ref={this.businessesAreaRef}>
                    {/* start business buttons */}
                    {dashboardData['businesses'].map((businessDetail) => {
                      return (
                        <div
                          key={businessDetail.key}
                          onClick={() =>
                            this.handleDashboardBusinessButtonClick(
                              businessDetail
                            )
                          }
                          className={
                            (businessDetail.status &&
                            businessDetail.status === 'existing'
                              ? 'existing-business-btn'
                              : '') +
                            ' btn business-btn btn-outline-dark ml-2 mr-2'
                          }>
                          <p>
                            {businessDetail.business_name &&
                            businessDetail.business_name.length > 0
                              ? businessDetail.business_name
                              : 'Add a New Business or Idea'}
                          </p>
                        </div>
                      );
                    })}

                    <div
                      onClick={() => this.handleDashboardPersonalButtonClick()}
                      className='btn existing-business-btn btn-outline-dark m-5'>
                      Personal <br />
                      Strategy
                    </div>
                  </div>
                  <div className='dashboard-goals-activities-section pt-5'>
                    <Goals
                      isBusinessPersonalColHidden={false}
                      businessesList={dashboardData.businesses}
                      goalsList={dashboardGoalsList}
                      linkedEntityKeys={linkedEntityKeys}
                      linkToOptions={linkToOptions}
                      name='business-goals'
                    />
                    <Activities
                      isBusinessPersonalColHidden={false}
                      businessesList={dashboardData.businesses}
                      linkedEntityKeys={linkedEntityKeys}
                      linkToOptions={linkToOptions}
                      activitiesList={dashboardActivitiesList}
                      goalsList={this.state.dashboardData.goals}
                      name='business-activities'
                    />
                  </div>
                </div>
              )}
            </div>{' '}
            {/* end dashboard area */}
            <SlidingPane
              className='business-details-pane'
              overlayClassName='pane-details-sliding-pane'
              isOpen={this.state.isBusinessDetailPaneOpen}
              title='Business Details'
              subtitle=''
              width='85%'
              from='right'
              onRequestClose={() => {
                this.setState({ isBusinessDetailPaneOpen: false });
              }}>
              <BusinessDetailForm
                dashboardId={this.state.dashboardId}
                selectedBusinessDetail={this.state.selectedBusinessDetail}
                currentUserDetails={this.props.currentUserDetails}
              />
            </SlidingPane>
          </React.Fragment>
        </div>
        <Footer currentUserDetails={this.props.currentUserDetails} />
      </div>
    );
  }
}

export default Dashboard;
