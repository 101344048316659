import React, { Component } from 'react';

import { db } from '../../../services/firebase';

import { Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import dayjs from 'dayjs';

export default class ProductServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbProductsServicesCollectionRef: db
        .collection('dashboards')
        .doc(this.props.linkedEntityKeys.dashboard_id)
        .collection('businesses')
        .doc(this.props.linkedEntityKeys.linked_entity_id)
        .collection('products_services'),
      selectedBusinessId: this.props.selectedBusinessId,
      currentDashboardId: this.props.currentDashboardId,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingProductsServicesForm: false,
      productServiceDetail: this.props.currentProductServiceItem,
      linkedEntityKeys: this.props.linkedEntityKeys,
      productServiceTotal:
        (parseFloat(this.props.currentProductServiceItem.sell_price) -
          parseFloat(this.props.currentProductServiceItem.cost_price)) *
        parseFloat(this.props.currentProductServiceItem.num_times),
      breakEvenTotal:
        parseFloat(this.props.totalGrossProfitNeeded) /
        ((parseFloat(this.props.currentProductServiceItem.sell_price) -
          parseFloat(this.props.currentProductServiceItem.cost_price)) *
          parseFloat(this.props.currentProductServiceItem.num_times)),
      targetTotal:
        parseFloat(this.props.currentProductServiceItem.target_amount) *
        parseFloat(this.props.currentProductServiceItem.target_frequency),
      breakevenHours:
        parseFloat(this.props.currentProductServiceItem.num_hours) *
        parseFloat(this.props.currentProductServiceItem.num_times) *
        (parseFloat(this.props.totalGrossProfitNeeded) /
          ((parseFloat(this.props.currentProductServiceItem.sell_price) -
            parseFloat(this.props.currentProductServiceItem.cost_price)) *
            parseFloat(this.props.currentProductServiceItem.num_times))),
      targetHours:
        parseFloat(this.props.currentProductServiceItem.target_amount) *
        parseFloat(this.props.currentProductServiceItem.target_frequency) *
        parseFloat(this.props.currentProductServiceItem.num_hours) *
        parseFloat(this.props.currentProductServiceItem.num_times),
    };

    this.myRef = React.createRef();
    this.handleProductServiceDetailChangeForm = this.handleProductServiceDetailChangeForm.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let data = {
      product_service_name: this.state.productServiceDetail
        .product_service_name,
      sell_price: this.state.productServiceDetail.sell_price
        ? this.state.productServiceDetail.sell_price
        : '0',
      cost_price: this.state.productServiceDetail.cost_price
        ? this.state.productServiceDetail.cost_price
        : '0',
      num_times: this.state.productServiceDetail.num_times
        ? this.state.productServiceDetail.num_times
        : '0',
      num_hours: this.state.productServiceDetail.num_hours
        ? this.state.productServiceDetail.num_hours
        : '0',
      notes: this.state.productServiceDetail.notes
        ? this.state.productServiceDetail.notes
        : '',
      target_amount: this.state.productServiceDetail.target_amount
        ? this.state.productServiceDetail.target_amount
        : '0',
      target_frequency: this.state.productServiceDetail.target_frequency
        ? this.state.productServiceDetail.target_frequency
        : '1',
      status: this.state.productServiceDetail.status,
      deleted: this.state.productServiceDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted) {
      db.collection('dashboards')
        .doc(this.state.linkedEntityKeys['dashboard_id'])
        .collection('recycled')
        .add(data)
        .then(function (docRef) {
          //console.log('Document written with ID: ', docRef.id);
        })
        .catch(function (error) {
          //   console.error('Error adding document: ', error);
        });

      this.state.dbProductsServicesCollectionRef
        .doc(this.props.currentProductServiceItem.key)
        .delete()
        .then(function () {
          //console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          // console.error('Error removing document: ', error);
        });
    } else {
      if (data.product_service_name.length > 1) {
        if (this.props.isNewProductService) {
          this.state.dbProductsServicesCollectionRef
            .add(data)
            .then(function (docRef) {
              //  console.log('Document written with ID: ', docRef.id);
            })
            .catch(function (error) {
              //   console.error('Error adding document: ', error);
            });
        } else {
          this.state.dbProductsServicesCollectionRef
            .doc(this.props.currentProductServiceItem.key)
            .update(data)
            .then(function () {
              // console.log('Business Detail updated');
            })
            .catch(function (e) {
              // console.error('There was an error: ', e);
            });
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingProductsServicesForm: true });

    let data = {
      product_service_name: '',
      sell_price: '',
      cost_price: '',
      num_times: '',
      num_hours: '',
      notes: '',
      status: '',
      target_amount: '',
      target_frequency: '',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewProductService) {
      this.setState({ productServiceDetail: data, productServiceTotal: 0.0 });
    }
    this.setState({ loadingProductServiceDetailForm: false });
  }

  async handleProductServiceDetailChangeForm(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let productServiceDetail = { ...this.state.productServiceDetail };
    productServiceDetail[name] = value;

    const productServiceTotal =
      (parseFloat(
        productServiceDetail.sell_price ? productServiceDetail.sell_price : 0.0
      ) -
        parseFloat(
          productServiceDetail.cost_price
            ? productServiceDetail.cost_price
            : 0.0
        )) *
      parseFloat(
        productServiceDetail.num_times ? productServiceDetail.num_times : 0
      );

    const breakEvenTotal =
      parseFloat(this.props.totalGrossProfitNeeded) / productServiceTotal;

    const breakevenHours =
      parseFloat(
        productServiceDetail.num_hours ? productServiceDetail.num_hours : 0
      ) *
      parseFloat(
        productServiceDetail.num_times ? productServiceDetail.num_times : 0
      ) *
      breakEvenTotal;

    const targetTotal =
      productServiceDetail.target_amount *
      productServiceDetail.target_frequency;

    const targetHours =
      targetTotal *
      parseFloat(
        productServiceDetail.num_hours ? productServiceDetail.num_hours : 0
      ) *
      parseFloat(
        productServiceDetail.num_hours ? productServiceDetail.num_times : 0
      );

    this.setState({
      productServiceDetail,
      productServiceTotal,
      breakEvenTotal,
      targetTotal,
      breakevenHours,
      targetHours,
    });
  }

  handleSaveProductServiceButton = () => {
    this.props.onProductServiceSaveDeleteButtonClicked(true);
  };

  handleDeleteProductServiceButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this product/service?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let productServiceDetail = { ...this.state.productServiceDetail };
            productServiceDetail['deleted'] = true;
            productServiceDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ productServiceDetail });
            this.props.onProductServiceSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const productServiceDetail = this.state.productServiceDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingProductServiceDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit details below. Changes will be made automatically. Close out
              of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='core-business-details'>
                <div className='form-group'>
                  <input
                    type='hidden'
                    className='form-control'
                    name='status'
                    value={productServiceDetail.status || 'active'}></input>
                  <label className='form_label' htmlFor='product_service_name'>
                    Product / Service Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Product / Service Name'
                    name='product_service_name'
                    type='text'
                    onChange={this.handleProductServiceDetailChangeForm}
                    value={
                      productServiceDetail.product_service_name || ''
                    }></input>
                </div>
                <div className='d-flex'>
                  <div className='form-group'>
                    <label className='form_label' htmlFor='sell_price'>
                      Sell Price
                    </label>
                    <input
                      className='form-control'
                      placeholder='0.00'
                      name='sell_price'
                      type='number'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.sell_price || ''}></input>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='cost_price'>
                      Cost Price
                    </label>
                    <input
                      className='form-control'
                      placeholder='0.00'
                      name='cost_price'
                      type='number'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.cost_price || ''}></input>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='num_times'>
                      Number of Times
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='0'
                      name='num_times'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.num_times || ''}></input>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='num_hours'>
                      Hours Required
                    </label>
                    <input
                      className='form-control'
                      placeholder='Hours Required'
                      name='num_hours'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.num_hours || ''}></input>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleProductServiceDetailChangeForm}
                    value={productServiceDetail.notes || ''}></textarea>
                </div>
                <hr />
                <div className='d-flex'>
                  <div className='form-group'>
                    <label className='form_label' htmlFor='target_amount'>
                      Target Number To Sell
                    </label>
                    <input
                      className='form-control'
                      placeholder='Target Number To Sell'
                      name='target_amount'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.target_amount || ''}></input>
                  </div>
                  <div className='form-group'>
                    <label className='form_label' htmlFor='target_frequency'>
                      Per
                    </label>
                    <select
                      className='form-control'
                      placeholder='Target Number To Sell'
                      name='target_frequency'
                      onChange={this.handleProductServiceDetailChangeForm}
                      value={productServiceDetail.target_frequency || '1'}>
                      <option value='365'>Day</option>
                      <option value='52'>Week</option>
                      <option value='26'>Fortnight</option>
                      <option value='12'>Month</option>
                      <option value='4'>Quarter</option>
                      <option value='1'>Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div className=''>
                <h5>Product Service Calculations</h5>
                <div className=''>
                  <Table className='no-borders' hover>
                    <thead>
                      <tr>
                        <td colSpan='7'>
                          Total Gross Profit Needed:{' '}
                          <NumberFormat
                            decimalScale='2'
                            value={this.props.totalGrossProfitNeeded}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                      <tr className=''>
                        <td>&nbsp;</td>
                        <td>Daily</td>
                        <td>Weekly</td>
                        <td>Fortnightly</td>
                        <td>Monthly</td>
                        <td>Quarterly</td>
                        <td>Yearly</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className=''>
                        <td>Gross Profit</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.productServiceTotal}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                      <tr className=''>
                        <td>Breakeven</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakEvenTotal}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                      </tr>
                      <tr className=''>
                        <td>Breakeven Hours</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.breakevenHours}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                      </tr>
                      <tr className=''>
                        <td>Target</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetTotal}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />
                        </td>
                      </tr>

                      <tr className=''>
                        <td>Target Hours</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.targetHours}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          />{' '}
                          <span className='small'>(hrs)</span>
                        </td>
                      </tr>

                      <tr className=''>
                        <td>Target Gross Profit</td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              (this.state.targetTotal *
                                this.state.productServiceTotal) /
                              365
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              (this.state.targetTotal *
                                this.state.productServiceTotal) /
                              52
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              (this.state.targetTotal *
                                this.state.productServiceTotal) /
                              26
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              (this.state.targetTotal *
                                this.state.productServiceTotal) /
                              12
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              (this.state.targetTotal *
                                this.state.productServiceTotal) /
                              4
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={
                              this.state.targetTotal *
                              this.state.productServiceTotal
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div
                  onClick={this.handleSaveProductServiceButton}
                  className='small btn btn-success mx-5'>
                  Save Changes
                </div>

                <div
                  onClick={this.handleDeleteProductServiceButton}
                  className='small btn btn-danger mx-5'>
                  Delete this Product/Service
                </div>
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
