import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import { db, auth } from '../services/firebase';

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      loadingAccountDetails: true,
      dbOperationMessage: '',
    };
    this.accountManagerAreaRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    //   this.handleDashboardClick = this.handleDashboardClick.bind(this);
    //   this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingProfileForm: true });
  }

  handleDashboardButtonClick() {
    window.location = '/dashboard';
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let currentUserDetails = { ...this.state.currentUserDetails };

    if (target.type === 'checkbox') {
      currentUserDetails[name] = target.checked;
    } else {
      currentUserDetails[name] = value;
    }
    this.setState({ currentUserDetails });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ dbOperationMessage: 'Saving changes.....' });

    /* update database and return message..... */
    try {
      const currentUserDetails = this.state.currentUserDetails;

      await db
        .collection('profiles')
        .doc(this.props.currentUserDetails['id'])
        .update(currentUserDetails)
        .then(() => {
          this.setState({ dbOperationMessage: 'Data saved....' });
          const timer = setTimeout(() => {
            this.setState({ dbOperationMessage: '' });
          }, 1000);
          return () => clearTimeout(timer);
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  render() {
    const currentUserDetails = this.state.currentUserDetails;

    console.log('account details', currentUserDetails);

    return (
      <div>
        <Header />
        <div className='d-flex flex-row'>
          <div className='col text-left'>
            <h3 className='mt-3'>
              Account Details{' '}
              {currentUserDetails ? (
                <React.Fragment>
                  {' '}
                  <span>
                    for <strong>{currentUserDetails.display_name}</strong>
                  </span>
                  <div
                    onClick={() => this.handleDashboardButtonClick()}
                    className='btn btn-outline-dark float-right'>
                    Continue to My Dashboard
                  </div>
                </React.Fragment>
              ) : (
                ''
              )}
            </h3>

            <hr />

            <h3>My Profile</h3>
            {this.state.dbOperationMessage ? (
              <p className='text-success'>{this.state.dbOperationMessage}</p>
            ) : null}
            {this.state.error ? (
              <p className='text-danger'>{this.state.error}</p>
            ) : null}

            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='form_label' htmlFor='display_name'>
                  Display Name
                </label>
                <input
                  className='form-control'
                  placeholder='Display Name'
                  name='display_name'
                  type='text'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.display_name || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='mobile_phone'>
                  Mobile Phone
                </label>
                <input
                  className='form-control'
                  placeholder='Mobile Phone'
                  name='mobile_phone'
                  type='text'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.mobile_phone || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='address'>
                  Address
                </label>
                <textarea
                  className='form-control'
                  placeholder='Address'
                  name='address'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.address || ''}></textarea>
              </div>

              <div
                onClick={this.handleSubmit}
                className='small btn btn-success mx-5'>
                Save Profile Changes
              </div>

              <hr />

              <h3>
                My Subscription{' '}
                {currentUserDetails ? (
                  <span>
                    {' '}
                    - {currentUserDetails.account_level}
                    <br />
                    Expiry -{' '}
                    {currentUserDetails.account_expiry ||
                      'No expiry date set yet'}
                  </span>
                ) : null}
              </h3>
              {/*     <div className='dflex'>
                <div
                  onClick={this.handleAppOnlyButtonClick}
                  className='small btn btn-success m-2'>
                  App Only $27.50 per month
                </div>

                <div
                  onClick={this.handleBoostButtonClick}
                  className='small btn btn-success m-2'>
                  Business Boost: $89.50 per month
                </div>

                <div
                  onClick={this.handlePowerUpButtonClick}
                  className='small btn btn-success m-2'>
                  Business PowerUp: $179.50 per month
                </div>
                </div> */}
              <div className='form-group'></div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountDetails;
