import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';

import { db } from '../../../services/firebase';

class MarketingDetailField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: this.props.fieldName,
      fieldNameLabel: this.props.fieldNameLabel,
      dataField: this.props.dataField,
      extraDetail: this.props.extraDetail,
      placeholderText: this.props.placeholderText,
      emptyFieldText: this.props.emptyFieldText,
      isViewOnly: this.props.isViewOnly,
      buttonText: this.props.buttonText,
      marketingDetail: this.props.marketingDetail,
      dbMarketingDetailRef: db
        .collection('dashboards')
        .doc(this.props.linkedEntityKeys['dashboard_id'])
        .collection('marketing')
        .doc(this.props.linkedEntityKeys['marketing_key']),
    };
  }

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  toggleExtraDetails = () => {
    let showExtraDetails = !this.state.isShowExtraDetails;
    this.setState({
      isShowExtraDetails: showExtraDetails,
    });
  };

  toggleViewMode = () => {
    if (!this.state.isViewOnly) {
      this.state.dbMarketingDetailRef
        .update({
          [this.props.dataField]: this.state.marketingDetail[
            this.props.dataField
          ],
        })
        .then(function () {
          // console.log('Business Detail updated');
        })
        .catch(function (e) {
          //  console.error('There was an error: ', e);
        });
    }

    let newViewMode = !this.state.isViewOnly;
    let buttonText = newViewMode ? 'Edit' : 'Save';

    this.setState({
      marketingDetail: this.props.marketingDetail,
      isViewOnly: newViewMode,
      buttonText: buttonText,
    });
  };

  handleBusinessDetailChangeForm = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let marketingDetail = { ...this.state.marketingDetail };
    marketingDetail[name] = value;
    this.setState({ marketingDetail });
  };

  renderFormControl = (inputType) => {
    switch (inputType) {
      case 'textarea':
        return (
          <textarea
            readOnly={this.state.isViewOnly}
            className='form-control w-75'
            placeholder={this.props.placeholderText}
            name={this.props.dataField}
            onChange={this.handleBusinessDetailChangeForm}
            value={
              this.state.marketingDetail[this.props.dataField] || ''
            }></textarea>
        );

      default:
        return (
          <input
            readOnly={this.state.isViewOnly}
            className='form-control w-75'
            placeholder={this.props.placeholderText}
            name={this.props.dataField}
            onChange={this.handleBusinessDetailChangeForm}
            value={
              this.state.marketingDetail[this.props.dataField] || ''
            }></input>
        );
    }
  };

  render() {
    return (
      <tr>
        <td>
          <div className='form-group'>
            <label
              onClick={() => this.toggleExtraDetails()}
              className='form_label'
              htmlFor={this.props.dataField}>
              <strong>{this.props.fieldNameLabel}</strong>
              <span className='small'>&nbsp;(expand)</span>
            </label>

            <Collapse in={this.state.isShowExtraDetails}>
              <div className='small'>{this.props.extraDetail}</div>
            </Collapse>

            {this.state.isViewOnly ? (
              <div className=''>
                {this.props.marketingDetail[this.props.dataField] || (
                  <span>{this.props.emptyFieldText}</span>
                )}
              </div>
            ) : (
              this.renderFormControl(this.props.inputType)
            )}

            <div
              className='btn-sml ml-auto mr-5 float-right btn btn-success'
              onClick={() => this.toggleViewMode()}>
              {this.state.buttonText} {this.props.fieldNameLabel}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default MarketingDetailField;
