import React, { Component } from 'react';
import { db } from '../../services/firebase';

export default class ProfileDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProfileId: this.props.selectedProfileId,
      currentUserDetails: this.props.currentUserDetails,
      readError: null,
      writeError: null,
      loadingProfileForm: false,
      profileDetails: {
        display_name: '',
        mobile_phone: '',
        email_address: '',
        role_level: '',
        approved_user: false,
        profile_complete: false,
      },
    };
    this.myRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingProfileForm: true });
    try {
      let profileDetails = [];

      db.ref('profiles/' + this.props.selectedProfileId).on(
        'value',
        (snapshot) => {
          let data = snapshot.val();
          profileDetails = {
            display_name: data.display_name,
            mobile_phone: data.mobile_phone,
            role_level: data.role_level,
            approved_user: data.approved_user,
            uid: data.uid,
          };
          // console.log(data.mobile_phone);

          if (data.display_name && data.mobile_phone) {
            db.ref('profiles/' + this.props.selectedProfileId).update({
              profile_complete: true,
            });
          } else {
            db.ref('profiles/' + this.props.selectedProfileId).update({
              profile_complete: false,
            });
          }

          this.setState({ profileDetails, loadingProfileForm: false });
        }
      );
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    db.ref('profiles/' + this.props.selectedProfileId).update({
      [name]: value,
    });
  }

  render() {
    const profileDetails = this.state.profileDetails;
    const currentUserDetails = this.state.currentUserDetails;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingProfileForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          {currentUserDetails.currentUserId === profileDetails.uid ||
          currentUserDetails.currentUserRoleLevel === 'admin' ? (
            <React.Fragment>
              <div className='small'>
                Edit your details below. Changes will be made automatically.
                Close out of this panel when finished
              </div>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <div className='form-group'>
                  <label className='form_label' htmlFor='display_name'>
                    Display Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Display Name'
                    name='display_name'
                    type='text'
                    onChange={this.handleInputChange}
                    value={profileDetails.display_name || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='mobile_phone'>
                    Mobile Phone
                  </label>
                  <input
                    className='form-control'
                    placeholder='Mobile Phone'
                    name='mobile_phone'
                    type='text'
                    onChange={this.handleInputChange}
                    value={profileDetails.mobile_phone || ''}></input>
                </div>

                {this.state.currentUserDetails.currentUserRoleLevel ===
                'admin' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='form_label' htmlFor='role_level'>
                        Role Level
                      </label>
                      <select
                        className='form-control'
                        placeholder='Role Level'
                        name='role_level'
                        onChange={this.handleInputChange}
                        value={profileDetails.role_level || ''}>
                        <option value='admin'>Admin</option>
                        <option value='edit'>Edit</option>
                        <option value='view'>View</option>
                      </select>
                    </div>
                    <div className='form-group'>
                      <label className='form_label' htmlFor='approved_user'>
                        Approved User
                      </label>
                      <select
                        className='form-control'
                        placeholder='Approved User'
                        name='approved_user'
                        onChange={this.handleInputChange}
                        value={profileDetails.approved_user}>
                        <option value='0'>No</option>
                        <option value='1'>Yes</option>
                      </select>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>{profileDetails.role_level}</div>
                  </React.Fragment>
                )}

                <div className='form-group'>
                  {this.state.error ? (
                    <p className='text-danger'>{this.state.error}</p>
                  ) : null}
                </div>
              </form>
            </React.Fragment>
          ) : (
            <div className='mt-1 py-3 px-3'>
              <div>{profileDetails.display_name}</div>
              <div>{profileDetails.mobile_phone}</div>
              <div>{profileDetails.email_address}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
