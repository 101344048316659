import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { auth } from '../../../services/firebase';

import MarketingDetailField from './MarketingDetailField';

class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
      marketingDetail: this.props.marketingDetail,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Marketing</strong>
        </h4>

        <div className='table-responsive-sm'>
          <Table className='no-borders' hover>
            <tbody>
              <MarketingDetailField
                fieldName='TargetMarket'
                fieldNameLabel='Target Market Overview'
                dataField='target_market'
                extraDetail={
                  <div className=''>
                    Who is the target market for the products/services this
                    business sells?
                    <br />
                    Key factors for target market may include geographical
                    location, online platforms, gender, age groups, values,
                    beliefs, common issues/problems, size of the market (how
                    many can buy), etc. Working this out will assist with better
                    marketing efforts
                    <br />
                    <i>
                      (Coming soon... ability to add multiple targets and be
                      able to link to products/services)
                    </i>
                  </div>
                }
                placeholderText='Start describing who the target market would be for this business'
                emptyFieldText='No Target Market details entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='IdealClient'
                fieldNameLabel='Ideal Customer/Client Profile'
                dataField='ideal_client'
                extraDetail={
                  <div className=''>
                    What does the ideal customer/client look like?
                    <br />
                    Taking the Target Market a step further, in here describe
                    what would the ideal customer or client would be like. The
                    aim is to be as descriptive and specific as possible.
                    <br />
                    <i>
                      (Coming soon... ability to add multiple ideal client
                      profiles and be able to link to products/services.)
                    </i>
                  </div>
                }
                placeholderText='Enter ideal customer/client profile'
                emptyFieldText='No Ideal Customer/Client Profile entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='KeyMessages'
                fieldNameLabel='Key Messages Overview'
                dataField='key_messages'
                extraDetail={
                  <div className=''>
                    What are the key messages that help the target market
                    understand more about the business product/service
                    offerings?
                    <br />
                    Develop and descibe key messages or phrases that help convey
                    what problems are solved, key benefits, reasons why the
                    products / services need to be purchased:
                    <br />
                    <i>
                      (Coming soon... ability to add multiple messages and be
                      able to link to products/services)
                    </i>
                  </div>
                }
                placeholderText='Enter the the key messages that will help people understand more'
                emptyFieldText='No key messages have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='ElevatorPitch'
                fieldNameLabel='Elevator Pitch'
                dataField='elevator_pitch'
                extraDetail={
                  <div className=''>
                    What is a short one line that you can say about your
                    business/product/service that will engage further
                    conversation?
                    <br />
                    Elevator pitches gives you something to say about your
                    business, product and/or service when in any networking
                    situation and someone is asking what do you do?
                    <br />
                    <i>
                      (Coming soon... ability to add multiple pitches and be
                      able to link to products/services)
                    </i>
                  </div>
                }
                placeholderText='Enter an elevator pitch that can be rehearsed'
                emptyFieldText='No elevator pitch has been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='ProspectJourney'
                fieldNameLabel='Prospect Journey Overview'
                dataField='prospect_journey'
                extraDetail={
                  <div className=''>
                    What are the ways a prospect can start engaging with this
                    business?
                    <br />A prospect has not purchased from this business yet.
                    Start to describe the marketing funnel that a prospect might
                    become engaged in order to develop relationship and
                    familiarity with this business/product/service.
                  </div>
                }
                placeholderText='Enter key steps a prospect will likely engage in relation to this business'
                emptyFieldText='No prospect journey has been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='MarketingFunnel'
                fieldNameLabel='Marketing Funnel'
                dataField='marketing_funnel'
                extraDetail={
                  <div className=''>
                    What are the key marketing strategies used to engage
                    prospects and facilitate a build of the relationship?
                    <br />
                    The marketing funnel usually includes key platforms and
                    strategies where prospects can find out about this business,
                    eg online: social media, website, email marketing, etc
                    offline: flyers, networking events, word of mouth, referrals
                    <br />
                    <i>
                      (Coming soon... ability to add individual marketing funnel
                      strategies)
                    </i>
                  </div>
                }
                placeholderText='Enter marketing funnel strategies'
                emptyFieldText='No marketing funnel strategies have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='MarketingGoals'
                fieldNameLabel='Marketing Goals'
                dataField='marketing_goals'
                extraDetail={
                  <div className=''>
                    What key goals does this business have in relation to it's
                    marketing efforts?
                    <br />
                    <i>
                      (Coming soon... ability to add individual goals to the
                      whole of strategy goals)
                    </i>
                  </div>
                }
                placeholderText='Enter marketing goals'
                emptyFieldText='No marketing goals have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />

              <MarketingDetailField
                fieldName='MarketingActivities'
                fieldNameLabel='Marketing Activities'
                dataField='marketing_activities'
                extraDetail={
                  <div className=''>
                    What are the key marketing activities planned for this
                    business?
                    <br />
                    Using information from Prospect Journey, Marketing Funnel
                    and Marketing Goals, outline key marketing activities and
                    estimated time to spend on these activities:
                    <br />
                    <i>
                      (Coming soon... ability to add individual marketing
                      activities to the whole of strategy activities)
                    </i>
                  </div>
                }
                placeholderText='Enter marketing activities'
                emptyFieldText='No marketing activities have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                marketingDetail={this.props.marketingDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
              />
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default Marketing;
