import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class TimeManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Time Management</strong>
        </h4>
        This section is currently being developed.
        <br />
        It will be about goals you want to achieve and what activities you can
        put in place to improve time management to get the most out of your time
        working with this business. The key questions to ask are:
        <br />
        -> What processes are in place to track time usage?
        <br />
        -> How many hours per week do you have available for this business?
        <br />
        -> What does the ideal week look like (what activities are scheduled)?
        <br />
        -> What can be outsourced?
        <br />
        -> What goals do you want to achievearound this?
        <br />
      </div>
    );
  }
}

export default TimeManagement;
