import React, { Component } from 'react';

import { auth } from '../../services/firebase';

class ProfileTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>Profile Tasks</h4>
        This section is currently being developed.
        <br />
      </div>
    );
  }
}

export default ProfileTasks;
