import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import GoalDetailForm from '../goals/GoalDetailForm';
import dayjs from 'dayjs';

import { auth } from '../../../services/firebase';

class Goals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      isGoalDetailPaneOpen: false,
      currentGoalDetail: [],
      goalsList: this.props.goalsList,
      businessesList: this.props.businessesList,
      linkedEntityKeys: this.props.linkedEntityKeys,
    };

    this.handleAddNewGoalButton = this.handleAddNewGoalButton.bind(this);
  }

  handleAddNewGoalButton = () => {
    this.setState({
      isNewGoal: true,
      isGoalDetailPaneOpen: true,
    });
  };

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleGoalSaveDeleteButtonClick = (value) => {
    this.setState({ isGoalDetailPaneOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'linked_entity_id',
        text: 'Business/Personal',
        formatter: (cell) => {
          let filteredBusiness = this.props.businessesList.filter(
            (businessItem) => businessItem.key.includes(cell)
          );
          if (
            filteredBusiness[0] &&
            filteredBusiness[0].business_name.length > 1
          ) {
            return filteredBusiness[0].business_name;
          }
          return 'Personal';
        },
        sort: true,
        hidden: this.props.isBusinessPersonalColHidden,
      },
      {
        dataField: 'short_name',
        text: 'Goal',
        sort: true,
      },

      {
        dataField: 'review_date',
        text: 'Review Date',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },
      {
        dataField: 'target_date',
        text: 'Target Date',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },
      {
        dataField: 'percent_complete',
        text: 'Percent Complete',
        sort: true,
        align: 'center',
        formatter: (cell) => {
          return cell + '%';
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'review_date',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentGoalDetail: row,
          isNewGoal: false,
          isGoalDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div>
        <div className='d-flex'>
          <h4>My Brilliant Goals</h4>
          <div
            onClick={() => this.handleAddNewGoalButton()}
            className='btn btn-outline-dark ml-auto'>
            <span>Add New Goal</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no goals to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={this.props.goalsList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='goal-detail-pane'
          overlayClassName='pane-goal-sliding-pane'
          isOpen={this.state.isGoalDetailPaneOpen}
          title='Goal Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isGoalDetailPaneOpen: false });
          }}>
          <GoalDetailForm
            businessesList={this.props.businessesList}
            currentGoalDetail={this.state.currentGoalDetail}
            linkedEntityKeys={this.props.linkedEntityKeys}
            linkToOptions={this.props.linkToOptions}
            isNewGoal={this.state.isNewGoal}
            isBusinessPersonalColHidden={this.props.isBusinessPersonalColHidden}
            currentUserId={auth().currentUser.uid}
            onGoalSaveDeleteButtonClicked={() =>
              this.handleGoalSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default Goals;
