import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {
    return (
      <footer className=''>
        <div className='pt-5 container text-center mt-auto'>
          <p>
            &copy; Brilliant Success Biz {new Date().getFullYear()}.<br />
            <span className='footer-version'>v20210311.0814</span>
            <br />
            {this.props.currentUserDetails ? (
              <span className='small'>
                Signed as {this.props.currentUserDetails.display_name}
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
