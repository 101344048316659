import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import ProfileDetailForm from '../profile/ProfileDetailForm';
import dayjs from 'dayjs';

import { auth } from '../../services/firebase';

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProfileDetailPaneOpen: false,
      currentProfileDetails: [],
      profilesList: this.props.profilesList,
    };

    //  this.handleAddNewGoalButton = this.handleAddNewGoalButton.bind(this);
  }

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
    console.log('profiles js did mount');
  }

  handleProfileSaveDeleteButtonClick = (value) => {
    this.setState({ isProfileDetailPaneOpen: false });
  };

  render() {
    console.log('profiles js', this.props);
    const columns = [
      {
        dataField: 'key',
        text: 'Key',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'mobile_phone',
        text: 'Mobile',
        sort: true,
      },
      {
        dataField: 'email_address',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'account_level',
        text: 'Level',
        sort: true,
      },
      {
        dataField: 'profile_complete',
        text: 'Profile Complete',
        sort: true,
        formatter: (cell) => {
          return cell ? 'Yes' : 'No';
        },
      },
      {
        dataField: 'dashboard_view',
        text: 'Dashboard',
        sort: false,
        isDummyField: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.props.onLoadNewDashboard(row.primary_dashboard_id);
          },
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div key={rowIndex} className='btn btn-sml btn-outline-dark'>
              Dashboard
            </div>
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'display_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentProfileDetails: row,
          isNewGoal: false,
          isGoalDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div>
        <div className='d-flex'>
          <h4>My Brilliant Profiles</h4>
        </div>
        <BootstrapTable
          noDataIndication='There are no profiles to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={this.props.profilesList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='profile-detail-pane'
          overlayClassName='pane-profile-sliding-pane'
          isOpen={this.state.isProfileDetailPaneOpen}
          title='Profile Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isProfileDetailPaneOpen: false });
          }}>
          <ProfileDetailForm
            currentProfileDetails={this.state.currentProfileDetails}
            currentUserId={auth().currentUser.uid}
            onProfileSaveDeleteButtonClicked={() =>
              this.handleProfileSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default Profiles;
