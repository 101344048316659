import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import SwitchComponents from '../helpers/switchComponents';

import PersonalSummary from '../components/strategy/personal/PersonalSummary';
import PersonalBudgets from '../components/strategy/personal/PersonalBudgets';
import Communication from '../components/strategy/communication/Communication';

import Goals from '../components/strategy/goals/Goals';
import Activities from '../components/strategy/activities/Activities';

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SubMenu,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faComments,
  faListAlt,
  faTrophy,
  faRunning,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

import { auth } from '../services/firebase';
import { db } from '../services/firebase';

class PersonalStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarIsCollapsed: false,
      activeComponent: 'personal-summary',
      loadingPersonalStrategy: true,
      loadingGoalsList: true,
      dashboardId: '',
      personalStrategyKey: '',
      personalStrategy: [],
      linkedEntityKeys: [],
      totalGoals: 0,
      totalActivities: 0,
      selectedUserDetails: [],
      budgetsList: [],
      budgetItems: [],
      subMenuOpen: {
        'communication-leadership': false,
      },
    };
    this.personalStrategyAreaRef = React.createRef();
    //   this.handleSidebarHeaderClick = this.handleSidebarHeaderClick.bind(this);
    //   this.handleDashboardClick = this.handleDashboardClick.bind(this);
    //   this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  async componentDidMount() {
    let pathArray = window.location.pathname.split('/');
    let dashboardId = pathArray[2];

    let personalStrategyKey = '';

    let goalsList = [];
    let linkedEntityKeys = [];
    let activitiesList = [];
    let totalGoals = 0;
    let totalActivities = 0;
    let personalStrategy = [];
    let userId = '';
    let selectedUserDetails = [];
    let linkToOptions = [];

    try {
      await db
        .collection('dashboards/' + dashboardId + '/businesses')
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            let data = doc.data();
            if (data.status === 'existing') {
              let linkTo = [];
              linkTo['key'] = doc.id;
              linkTo['name'] = data.business_name;
              linkToOptions.push(linkTo);
            }
          });
        })
        .then()
        .catch(function (next) {
          console.log(next);
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }

    db.collection('dashboards')
      .doc(dashboardId)
      .collection('personal')
      .get()
      .then((docRef) => {
        if (docRef.docs[0].exists) {
          personalStrategyKey = docRef.docs[0].id;
          let data = docRef.docs[0].data();

          userId = data.uid;

          if (personalStrategyKey && dashboardId) {
            personalStrategy = docRef.docs[0].data();
            personalStrategy['dashboard_id'] = dashboardId;
            personalStrategy['personal_strategy_id'] = personalStrategyKey;

            linkedEntityKeys['dashboard_id'] = dashboardId;
            linkedEntityKeys['linked_entity_id'] = personalStrategyKey;
            linkedEntityKeys['source'] = 'personal_strategy';

            let linkTo = [];
            linkTo['key'] = personalStrategyKey;
            linkTo['name'] = 'Personal';
            linkToOptions.push(linkTo);
            db.collection('profiles')
              .where('uid', '==', userId)
              .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                  const data = doc.data();
                  data['key'] = doc.id;
                  selectedUserDetails.push(data);
                });
              });

            let budgetsList = [];

            db.collection('dashboards')
              .doc(linkedEntityKeys['dashboard_id'])
              .collection('personal')
              .doc(linkedEntityKeys['linked_entity_id'])
              .collection('budgets')
              .onSnapshot((snapshot) => {
                budgetsList = [];

                snapshot.forEach((doc) => {
                  const data = doc.data();
                  data['key'] = doc.id;

                  budgetsList.push(data);
                });
                this.setState({ budgetsList });
              });

            try {
              db.collection('dashboards/' + dashboardId + '/personal')
                .onSnapshot((snapshot) => {
                  snapshot.forEach((doc) => {
                    const data = doc.data();
                    linkedEntityKeys['personal_key'] = doc.id;
                  });
                })
                .then()
                .catch(function (next) {
                  //console.log(next);
                });
            } catch (error) {
              this.setState({ readError: error.message });
            }

            this.setState({
              selectedUserDetails,
              linkedEntityKeys,
              linkToOptions,
              personalStrategy,
            });

            try {
              db.collection('dashboards/' + dashboardId + '/goals')
                .where('completed', '==', false)
                .where('linked_entity_id', '==', personalStrategyKey)
                .onSnapshot((snapshot) => {
                  goalsList = [];

                  snapshot.forEach((doc) => {
                    const data = doc.data();
                    data['key'] = doc.id;
                    goalsList.push(data);
                  });
                  totalGoals = goalsList.length;
                  this.setState({ goalsList, totalGoals });
                });
            } catch (error) {
              console.log('Error getting document:', error);
            }

            db.collection('dashboards/' + dashboardId + '/activities')
              .where('linked_entity_id', '==', personalStrategyKey)
              .onSnapshot((snapshot) => {
                activitiesList = [];
                snapshot.forEach((doc) => {
                  const data = doc.data();
                  data['key'] = doc.id;

                  if (data.linked_goal !== 'None') {
                    let linkedGoal = goalsList.filter((goalItem) =>
                      goalItem.key.includes(data.linked_goal)
                    );
                    if (linkedGoal[0].short_name) {
                      data['linked_goal_name'] = linkedGoal[0].short_name;
                    }
                  } else {
                    data['linked_goal_name'] = 'No linked goal';
                  }

                  activitiesList.push(data);
                });
                totalActivities = activitiesList.length;
                this.setState({ activitiesList, totalActivities });
              });

            this.setState({
              activeComponent: 'personal-summary',
              dashboardId,
              personalStrategyKey,
            });
          } else {
            //      window.location = '/dashboard';
          }
        } else {
          // doc.data() will be undefined in this case
          console.log('No such personal section!');
          // window.location = '/dashboard';
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
        //    window.location = '/dashboard';
      });
  }

  handleSidebarHeaderClick = () => {
    const sideBarIsCollapsed = !this.state.sideBarIsCollapsed;
    this.setState({ sideBarIsCollapsed });
  };

  handleDashboardClick = () => {
    window.location = '/dashboard/' + this.state.dashboardId;
  };

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ activeComponent: selectedComponent });
  };

  handleSubMenuMouseEnterLeave = (sectionName, isSubMenuOpen) => {
    let subMenuOpen = this.state.subMenuOpen;
    subMenuOpen[sectionName] = isSubMenuOpen;
    this.setState({ subMenuOpen });
  };

  render() {
    return (
      <div>
        <Header />
        <div className='d-flex flex-row'>
          <div
            className='ml-auto'
            style={{ zIndex: '0', minHeight: 'calc(100vh - 120px)' }}>
            <ProSidebar className='' collapsed={this.state.sideBarIsCollapsed}>
              <SidebarHeader
                className='text-center sidebar-header-arrow'
                onClick={this.handleSidebarHeaderClick}>
                {this.state.sideBarIsCollapsed ? (
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                )}
              </SidebarHeader>
              <SidebarContent className=''>
                <Menu popperArrow='true' iconShape='circle'>
                  <MenuItem
                    className=''
                    icon={<FontAwesomeIcon icon={faHome} />}
                    onClick={() => this.handleDashboardClick()}>
                    Dashboard
                  </MenuItem>

                  <MenuItem
                    icon={<FontAwesomeIcon icon={faListAlt} />}
                    onClick={() =>
                      this.handleSwitchComponent('personal-summary')
                    }>
                    Personal Summary
                  </MenuItem>

                  <MenuItem
                    icon={<FontAwesomeIcon icon={faTrophy} />}
                    onClick={() =>
                      this.handleSwitchComponent('personal-goals')
                    }>
                    Goals
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faRunning} />}
                    onClick={() =>
                      this.handleSwitchComponent('personal-activities')
                    }>
                    Activities
                  </MenuItem>
                </Menu>
                <hr />
                <Menu popperArrow='true' iconShape='circle'>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    onClick={() =>
                      this.handleSwitchComponent('personal-budgets')
                    }>
                    Personal Budgets
                  </MenuItem>
                  <SubMenu
                    open={this.state.subMenuOpen['communication-leadership']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'communication-leadership',
                        true
                      )
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'communication-leadership',
                        false
                      )
                    }
                    onClick={() =>
                      this.handleSwitchComponent('communication-leadership')
                    }
                    icon={<FontAwesomeIcon icon={faComments} />}
                    title={<strong>Communcation</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('communication-leadership')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['communication-leadership'] ? (
                        <div className='pt-1'>
                          <strong>Communication</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Communication
                        <br />
                        Emotional Intelligence
                        <br />
                        Leadership
                      </div>
                    </MenuItem>
                  </SubMenu>

                  {/* <SubMenu title='B'>
                    <MenuItem>Component 1</MenuItem>
                    <MenuItem>Component 2</MenuItem>
                  </SubMenu>*/}
                </Menu>
              </SidebarContent>
              <div className=''></div>
            </ProSidebar>
          </div>
          <div className='col text-left'>
            <h3 className='mt-3'>
              Personal Strategy{' '}
              {this.state.selectedUserDetails[0] ? (
                <span>
                  for{' '}
                  <strong>
                    {this.state.selectedUserDetails[0].display_name}
                  </strong>
                </span>
              ) : (
                ''
              )}
            </h3>
            <hr />
            {/* SwitchComponents need more than 1 child element */}
            <SwitchComponents active={this.state.activeComponent}>
              <PersonalSummary
                name='personal-summary'
                personalStrategy={this.state.personalStrategy}
                linkedEntityKeys={this.state.linkedEntityKeys}
                totalGoals={this.state.totalGoals}
                totalActivities={this.state.totalActivities}
              />
              <Goals
                isBusinessPersonalColHidden={true}
                goalsList={this.state.goalsList}
                linkedEntityKeys={this.state.linkedEntityKeys}
                linkToOptions={this.state.linkToOptions}
                name='personal-goals'
              />
              <Activities
                isBusinessPersonalColHidden={true}
                activitiesList={this.state.activitiesList}
                goalsList={this.state.goalsList}
                linkedEntityKeys={this.state.linkedEntityKeys}
                linkToOptions={this.state.linkToOptions}
                name='personal-activities'
              />
              <PersonalBudgets
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='personal-budgets'
                personalStrategy={this.state.personalStrategy}
                budgetsList={this.state.budgetsList}
              />

              <Communication
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='communication-leadership'
                personalStrategy={this.state.personalStrategy}
              />

              <div name='blank'>&nbsp;</div>
            </SwitchComponents>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalStrategy;
