import React, { Component } from 'react';
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { db } from '../../../services/firebase';

import { copyDoc } from '../../../helpers/globalFunctions';

export default class BudgetDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbBudgetDetailRef: db
        .collection('dashboards')
        .doc(this.props.currentDashboardId)
        .collection('personal')
        .doc(this.props.personalStrategyKey)
        .collection('budgets'),
      selectedBudgetId: this.props.selectedBudgetId,
      currentDashboardId: this.props.currentDashboardId,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingBudgetDetailForm: false,
      budgetDetail: {
        budget_name: '',
        status: 'new',
        start_date: '',
        review_date: '',
        notes: '',
        total_income: '',
        total_expenses: '',
        inc_exp_diff: '',
        deleted: false,
        purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      },
    };
    this.myRef = React.createRef();
    this.handleBudgetDetailChangeform = this.handleBudgetDetailChangeform.bind(
      this
    );
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let data = {
      budget_name: this.state.budgetDetail.budget_name,
      start_date: this.state.budgetDetail.start_date,
      review_date: this.state.budgetDetail.review_date,
      notes: this.state.budgetDetail.notes,
      updated_dts: dayjs().toString(),
      deleted: this.state.budgetDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted) {
      let recycledRef = db
        .collection('dashboards')
        .doc(this.props.currentDashboardId)
        .collection('recycled');

      //copy budget detail to recycled
      copyDoc(
        this.state.dbBudgetDetailRef,
        this.props.selectedBudgetDetail['key'],
        recycledRef,
        {},
        false
      );
      // loop through budget items to copy and then delete
      this.state.dbBudgetDetailRef
        .doc(this.props.selectedBudgetDetail['key'])
        .collection('items')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            copyDoc(
              this.state.dbBudgetDetailRef
                .doc(this.props.selectedBudgetDetail['key'])
                .collection('items'),
              doc.id,
              recycledRef
                .doc(this.props.selectedBudgetDetail['key'])
                .collection('items'),
              {},
              false
            ).then(() => {
              this.state.dbBudgetDetailRef
                .doc(this.props.selectedBudgetDetail['key'])
                .collection('items')
                .doc(doc.id)
                .delete();
            });
          });
        })
        .then(() => {
          this.state.dbBudgetDetailRef
            .doc(this.props.selectedBudgetDetail['key'])
            .delete();
        });
    } else {
      if (this.state.budgetDetail.budget_name.length > 0) {
        if (this.props.isNewBudget) {
          data['status'] = 'new';

          this.state.dbBudgetDetailRef.add(data);
        } else {
        }
        data['status'] = 'existing';
        this.state.dbBudgetDetailRef
          .doc(this.props.selectedBudgetDetail['key'])
          .update(data)
          .then(function () {
            // console.log('Business Detail updated');
          })
          .catch(function (e) {
            //  console.error('There was an error: ', e);
          });
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingBudgetDetailForm: true });

    if (!this.props.isNewBudget) {
      this.setState({ budgetDetail: this.props.selectedBudgetDetail });
    }

    /* try {
      let budgetDetail = [];

      this.state.dbBudgetDetailRef.get().then((doc) => {
        const data = doc.data();

        budgetDetail = {
          budget_name: data.budget_name ? data.budget_name : '',
          status: data.status ? data.status : 'new',
          start_date: data.start_date ? data.start_date : '',
          review_date: data.review_date ? data.review_date : '',
          notes: data.notes ? data.notes : '',
          total_income: data.total_income ? data.total_income : '',
          total_expenses: data.total_expenses ? data.total_expenses : '',
          inc_exp_diff: data.inc_exp_diff ? data.inc_exp_diff : '',
        };

        this.setState({ loadingbudgetDetailForm: false });
      });
    } catch (error) {
      console.log('error', error);
      this.setState({ readError: error.message });
    }  */
  }

  async handleBudgetDetailChangeform(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let budgetDetail = { ...this.state.budgetDetail };
    budgetDetail[name] = value;
    this.setState({ budgetDetail });
  }

  handleSaveBudgetButton = () => {
    this.props.onBudgetSaveDeleteButtonClicked(true);
  };

  handleDeleteBudgetButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message:
        'Are you sure to delete this budget?  All related budget items will also be removed.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let budgetDetail = { ...this.state.budgetDetail };
            budgetDetail['deleted'] = true;
            budgetDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ budgetDetail });
            this.props.onBudgetSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const budgetDetail = this.state.budgetDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingbudgetDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit details below. Changes will be made automatically. Close out
              of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='budget-details'>
                <div className='form-group'>
                  <input
                    type='hidden'
                    className='form-control'
                    name='status'
                    value={budgetDetail.status || 'new'}></input>
                  <label className='form_label' htmlFor='budget_name'>
                    Budget Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Budget Name'
                    name='budget_name'
                    type='text'
                    onChange={this.handleBudgetDetailChangeform}
                    value={budgetDetail.budget_name || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='start_date'>
                    Start Date
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Start Date'
                    name='start_date'
                    type='date'
                    onChange={this.handleBudgetDetailChangeform}
                    value={budgetDetail.start_date || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='review_date'>
                    Review Date
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Review Date'
                    name='review_date'
                    type='date'
                    onChange={this.handleBudgetDetailChangeform}
                    value={budgetDetail.review_date || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleBudgetDetailChangeform}
                    value={budgetDetail.notes || ''}></textarea>
                </div>
              </div>
              <div
                onClick={this.handleSaveBudgetButton}
                className='small btn btn-success mx-5'>
                Save Changes
              </div>

              <div
                onClick={this.handleDeleteBudgetButton}
                className='small btn btn-danger mx-5'>
                Delete this Budget
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
