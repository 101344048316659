import React, { Component } from 'react';
import { db } from '../../../services/firebase';

//Import dayjs for date and time
import dayjs from 'dayjs';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { stringToBoolean } from '../../../helpers/globalFunctions';

export default class GoalDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedEntityKeys: this.props.linkedEntityKeys,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingGoalDetailForm: false,
      goalDetail: [],
      isNewGoal: this.props.isNewGoal,
      filteredBusinessList: [],
    };
    this.myRef = React.createRef();
    this.handleGoalDetailFormChange = this.handleGoalDetailFormChange.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let data = {
      linked_entity_id: this.state.goalDetail.linked_entity_id,
      short_name: this.state.goalDetail.short_name,
      description: this.state.goalDetail.description,
      target_date: this.state.goalDetail.target_date,
      review_date: this.state.goalDetail.review_date,
      estimated_time: this.state.goalDetail.estimated_time,
      urgency_level: this.state.goalDetail.urgency_level,
      importance_level: this.state.goalDetail.importance_level,
      importance_urgency: 'TBA',
      percent_complete: this.state.goalDetail.percent_complete,
      measurement_notes: this.state.goalDetail.measurement_notes,
      reward_notes: this.state.goalDetail.reward_notes,
      dashboard_display: stringToBoolean(
        this.state.goalDetail.dashboard_display
      ),
      completed: this.state.goalDetail.completed,
      deleted: this.state.goalDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted || data.completed) {
      db.collection('dashboards')
        .doc(this.state.linkedEntityKeys['dashboard_id'])
        .collection('recycled')
        .add(data)
        .then(function (docRef) {
          //  console.log('Document written with ID: ', docRef.id);
        })
        .catch(function (error) {
          //   console.error('Error adding document: ', error);
        });

      db.collection('dashboards')
        .doc(this.props.linkedEntityKeys['dashboard_id'])
        .collection('goals')
        .doc(this.state.goalDetail['key'])
        .delete()
        .then(function () {
          //console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          // console.error('Error removing document: ', error);
        });
    } else {
      if (data.short_name.length > 1) {
        if (this.state.isNewGoal) {
          db.collection('dashboards')
            .doc(this.state.linkedEntityKeys['dashboard_id'])
            .collection('goals')
            .add(data)
            .then(function (docRef) {
              //  console.log('Document written with ID: ', docRef.id);
            })
            .catch(function (error) {
              //   console.error('Error adding document: ', error);
            });
        } else {
          db.collection('dashboards')
            .doc(this.props.linkedEntityKeys['dashboard_id'])
            .collection('goals')
            .doc(this.state.goalDetail['key'])
            .update(data);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingGoalDetailForm: true });

    if (this.props.businessesList) {
      let filteredBusinessList = this.props.businessesList.filter(
        (businessItem) => businessItem.status.includes('existing')
      );
      this.setState({ filteredBusinessList });
    }

    const newGoalDetail = {
      linked_entity_id: this.props.linkedEntityKeys['linked_entity_id'],
      short_name: '',
      description: '',
      target_date: '',
      review_date: '',
      estimated_time: '',
      urgency_level: '0',
      importance_level: '0',
      importance_urgency: '0',
      percent_complete: '0',
      measurement_notes: '',
      reward_notes: '',
      dashboard_display: false,
      completed: false,
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewGoal) {
      this.setState({ goalDetail: newGoalDetail });
    } else {
      this.handleChangeImportanceLevel(
        this.props.currentGoalDetail['importance_level']
      );
      this.handleChangeUrgencyLevel(
        this.props.currentGoalDetail['urgency_level']
      );
      this.handleChangePercentComplete(
        this.props.currentGoalDetail['percent_complete']
      );

      this.setState({ goalDetail: this.props.currentGoalDetail });
    }

    this.setState({ loadingGoalDetailForm: false });
  }

  async handleGoalDetailFormChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let goalDetail = { ...this.state.goalDetail };

    if (target.type === 'checkbox') {
      goalDetail[name] = target.checked;
    } else {
      goalDetail[name] = value;
    }
    this.setState({ goalDetail });
  }

  handleSaveGoalButton = () => {
    this.props.onGoalSaveDeleteButtonClicked(true);
  };

  handleDeleteGoalButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this goal?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let goalDetail = { ...this.state.goalDetail };
            goalDetail['deleted'] = true;
            goalDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ goalDetail });
            this.props.onDeleteGoalButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleChangeImportanceLevel = (value) => {
    let goalDetail = { ...this.state.goalDetail };
    goalDetail['importance_level'] = value;
    this.setState({
      importanceRange: value,
      goalDetail,
    });
  };

  handleChangeUrgencyLevel = (value) => {
    let goalDetail = { ...this.state.goalDetail };
    goalDetail['urgency_level'] = value;
    this.setState({
      urgencyRange: value,
      goalDetail,
    });
  };

  handleChangePercentComplete = (value) => {
    let goalDetail = { ...this.state.goalDetail };
    goalDetail['percent_complete'] = value;
    this.setState({
      percentCompleteRange: value,
      goalDetail,
    });
  };

  render() {
    const goalDetail = this.state.goalDetail;

    const { importanceRange, urgencyRange, percentCompleteRange } = this.state;
    const importanceRangeLabels = {
      0: 'Low',
      50: 'Medium',
      100: 'High',
    };

    const urgencyRangeLabels = {
      0: 'Low',
      50: 'Medium',
      100: 'High',
    };

    const percentCompleteRangeLabels = {
      0: '0',
      50: '50',
      100: '100',
    };

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingGoalDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='form_label' htmlFor='linked_entity_id'>
                  Link To
                </label>
                <select
                  className='form-control'
                  name='linked_entity_id'
                  onChange={this.handleGoalDetailFormChange}
                  value={
                    goalDetail.linked_entity_id
                      ? goalDetail.linked_entity_id
                      : this.props.linkedEntityKeys['linked_entity_id']
                  }>
                  {this.props.linkToOptions.map((item, key) => {
                    return (
                      <option key={key} value={item.key}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='business_name'>
                  Goal Short Name
                </label>
                <input
                  className='form-control'
                  placeholder='Goal Short Name'
                  name='short_name'
                  type='text'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.short_name || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='description'>
                  Description
                </label>
                <textarea
                  className='form-control'
                  placeholder='Description'
                  name='description'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.description || ''}></textarea>
              </div>

              <div className='form-group w-50'>
                <label className='form_label' htmlFor='target_date'>
                  Target Date
                </label>
                <input
                  className='form-control'
                  placeholder='Target Date'
                  name='target_date'
                  type='date'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.target_date || ''}></input>
              </div>

              <div className='form-group w-50'>
                <label className='form_label' htmlFor='review_date'>
                  Review Date
                </label>
                <input
                  className='form-control'
                  placeholder='Review Date'
                  name='review_date'
                  type='date'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.review_date || ''}></input>
              </div>

              <div className='form-group w-25'>
                <label className='form_label' htmlFor='estimated_time'>
                  Estimated Time To Complete (Hrs)
                </label>
                <input
                  className='form-control'
                  placeholder='Est. Time'
                  name='estimated_time'
                  type='text'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.estimated_time || ''}></input>{' '}
                Hrs
              </div>

              <div className='form-group w-50 mb-10'>
                <label className='form_label' htmlFor='importance_level'>
                  Importance
                </label>

                <Slider
                  name='importance_level'
                  min={0}
                  max={100}
                  step={50}
                  tooltip={false}
                  value={importanceRange}
                  labels={importanceRangeLabels}
                  onChange={this.handleChangeImportanceLevel}
                />
              </div>

              <div className='form-group w-50 mb-10'>
                <label className='form_label' htmlFor='urgency_level'>
                  Urgency
                </label>

                <Slider
                  name='urgency_level'
                  min={0}
                  max={100}
                  step={50}
                  tooltip={false}
                  value={urgencyRange}
                  labels={urgencyRangeLabels}
                  onChange={this.handleChangeUrgencyLevel}
                />
              </div>

              <div className='form-group w-75 mb-10'>
                <label className='form_label' htmlFor='percent_complete'>
                  Percent Complete
                </label>
                <Slider
                  name='precent_complete'
                  min={0}
                  max={100}
                  step={5}
                  tooltip={false}
                  value={percentCompleteRange}
                  labels={percentCompleteRangeLabels}
                  onChange={this.handleChangePercentComplete}
                />
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='measurement_notes'>
                  Measurement Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Measurement Notes'
                  name='measurement_notes'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.measurement_notes || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='reward_notes'>
                  Reward Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Reward Notes'
                  name='reward_notes'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.reward_notes || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='dashboard_display'>
                  Display on Dashboard?
                </label>
                <select
                  readOnly={this.state.isViewMode}
                  className='form-control'
                  placeholder='Dashboard Display'
                  name='dashboard_display'
                  onChange={this.handleGoalDetailFormChange}
                  value={goalDetail.dashboard_display || '0'}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleSaveGoalButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>

            <div
              onClick={this.handleDeleteGoalButton}
              className='small btn btn-danger'>
              Delete this Goal
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
