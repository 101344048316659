import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import Profiles from '../components/profile/Profiles';
import ProfileTasks from '../components/profile/ProfileTasks';

import { db } from '../services/firebase';

import SwitchComponents from '../helpers/switchComponents';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faHandshake,
  faUsers,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import AdvisorMeetings from '../components/advisor-meetings/AdvisorMeetings';

let unSubSnapshot = {};

class ProfileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      loadingProfileManager: true,
      profilesList: [],
      componentData: {},
      sideBarIsCollapsed: false,
      activeComponent: 'profiles',
    };
    this.ClientManagerAreaRef = React.createRef();
    //   this.handleSidebarHeaderClick = this.handleSidebarHeaderClick.bind(this);
    //   this.handleDashboardClick = this.handleDashboardClick.bind(this);
    //   this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  async addCollectionSnapshot(dbRef, collection) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[collection] = dbRef
          .collection(collection)
          .onSnapshot((snapshot) => {
            let setStateData = [];
            let componentData = this.state.componentData;

            snapshot.forEach((doc) => {
              let snapshotData = [];
              snapshotData = doc.data();
              snapshotData['key'] = doc.id;
              setStateData.push(snapshotData);
            });

            componentData[collection] = setStateData;

            this.setState({ componentData });
            resolve(true);
          });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async addQuerySnapshot(dbRef, collection) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[collection] = dbRef.onSnapshot((snapshot) => {
          let setStateData = [];
          let componentData = this.state.componentData;

          snapshot.forEach((doc) => {
            let snapshotData = [];
            snapshotData = doc.data();
            snapshotData['key'] = doc.id;
            setStateData.push(snapshotData);
          });

          componentData[collection] = setStateData;

          this.setState({ componentData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async addDocumentSnapshot(dbRef, docId, collection) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[docId] = dbRef.doc(docId).onSnapshot((doc) => {
          let docData = doc.data();
          docData['id'] = doc.id;

          const componentData = {
            ...this.state.componentData,
            [collection]: docData,
          };
          this.setState({ componentData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  async getKeyfromDBRef(dbRef) {
    let result = '';
    try {
      await dbRef.get().then((snapshot) => {
        if (snapshot.docs[0].id) {
          result = snapshot.docs[0].id;
        }
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
    return result;
  }

  async componentDidMount() {
    const profilesDBRef = db.collection('profiles');
    try {
      Promise.all([
        //  this.addDocumentSnapshot(advisorDBRef, dashboardId),
        this.addQuerySnapshot(profilesDBRef, 'profiles'),
      ]).then((result) => {
        this.setState({ loadingProfileManager: false });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  handleSidebarHeaderClick = () => {
    const sideBarIsCollapsed = !this.state.sideBarIsCollapsed;
    this.setState({ sideBarIsCollapsed });
  };
  handleDashboardClick = () => {
    window.location = '/dashboard';
  };

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ activeComponent: selectedComponent });
  };

  render() {
    const loadingProfileManager = this.state.loadingProfileManager;
    console.log(this.state.componentData.profiles);
    return (
      <div>
        <Header />

        <div className='d-flex flex-row'>
          {loadingProfileManager ? (
            <div
              className='spinner-border text-success isDataLoadCompleted-indicator'
              role='status'>
              <span className='sr-only'>Loading... Profile Manager</span>
            </div>
          ) : (
            <React.Fragment>
              <div
                className='ml-auto'
                style={{ zIndex: '0', minHeight: 'calc(100vh - 120px)' }}>
                <ProSidebar
                  className=''
                  collapsed={this.state.sideBarIsCollapsed}>
                  <SidebarHeader
                    className='text-center sidebar-header-arrow'
                    onClick={this.handleSidebarHeaderClick}>
                    {this.state.sideBarIsCollapsed ? (
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    )}
                  </SidebarHeader>
                  <SidebarContent className=''>
                    <Menu popperArrow='true' iconShape='circle'>
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faHome} />}
                        onClick={() => this.handleDashboardClick()}>
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        onClick={() => this.handleSwitchComponent('profiles')}>
                        Profiles
                      </MenuItem>
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faHandshake} />}
                        onClick={() => this.handleSwitchComponent('tasks')}>
                        Tasks
                      </MenuItem>
                    </Menu>
                  </SidebarContent>
                  <div className=''></div>
                </ProSidebar>
              </div>

              <div className='col text-left'>
                <h3 className='mt-3'>
                  Profile Manager{' '}
                  {this.props.currentUserDetails ? (
                    <span>
                      for{' '}
                      <strong>
                        {this.props.currentUserDetails.display_name}
                      </strong>
                    </span>
                  ) : (
                    ''
                  )}
                </h3>
                <hr />
                {/* SwitchComponents need more than 1 child element */}
                <SwitchComponents active={this.state.activeComponent}>
                  <Profiles
                    name='profiles'
                    profilesList={this.state.componentData.profiles}
                    onLoadNewDashboard={this.props.onLoadNewDashboard}
                  />
                  <ProfileTasks
                    name='tasks'
                    profilesList={this.state.componentData.profiles}
                  />
                  <div name='blank'>&nbsp;</div>
                </SwitchComponents>
                <Footer />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ProfileManager;
