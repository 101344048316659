import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';

import { auth } from '../../../services/firebase';

import BusinessDetailForm from './BusinessDetailForm';
import BusinessDetailField from './BusinessDetailField';

import { Table } from 'react-bootstrap';

class BusinessDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      isBusinessDetailPaneOpen: false,
      linkedEntityKeys: this.props.linkedEntityKeys,
      businessDetail: this.props.businessDetail,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleBusinessEditButtonClick = () => {
    this.setState({
      isBusinessDetailPaneOpen: true,
    });
  };

  render() {
    let businessDetail = this.state.businessDetail;
    return (
      <div>
        <div className='d-flex'>
          <h4>
            My Brilliant <strong>Business Purpose</strong>
          </h4>

          <div
            className='ml-auto mr-5 float-right btn btn-success'
            onClick={() => this.handleBusinessEditButtonClick()}>
            Edit Business Details
          </div>
        </div>

        <div className='table-responsive-sm'>
          <Table className='no-borders' hover>
            <tbody>
              <BusinessDetailField
                fieldName='PurposeWhy'
                fieldNameLabel='Purpose / Why'
                dataField='purpose_why'
                extraDetail={
                  <div className=''>
                    What is the purpose for this business? WHY was this
                    businesses started?
                    <br />
                    Describe the movitations for starting this business:
                  </div>
                }
                placeholderText='Enter the purpose or why this business was started'
                emptyFieldText='No Purpose or Why details entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
                includeClassName='form-control w-75'
              />

              <BusinessDetailField
                fieldName='PayYourselfFirst'
                fieldNameLabel='Pay Yourself First Amount'
                dataField='pay_yourself_first'
                extraDetail={
                  <div className=''>
                    How much income would you like this business to generate for
                    you?
                    <br />
                    Enter an amount that you would like this business to
                    generate over the next 12 months of this strategy:
                  </div>
                }
                placeholderText='Enter the Pay Yourself First amount'
                emptyFieldText='0'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='number'
                numberType='currency'
                includeClassName='form-control w-75'
              />

              <BusinessDetailField
                fieldName='KeyPeople'
                fieldNameLabel='Who Are the Key People Involved In This Business'
                dataField='key_people'
                extraDetail={
                  <div className=''>
                    Who are the key people involved in the running, management
                    and decisions for this b
                    <br />
                    Enter brief descriptions of the names and roles of key
                    people, stakeholders and decision makers:
                  </div>
                }
                placeholderText='Enter the the key people involved in this business'
                emptyFieldText='No key people details have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
                includeClassName='form-control w-75'
              />

              <BusinessDetailField
                fieldName='ProblemSolved'
                fieldNameLabel='What Problem Is This Business Addressing'
                dataField='problem_solved'
                extraDetail={
                  <div className=''>
                    What are the key problems that this business solves or helps
                    to solve?
                    <br />
                    Enter brief descriptions of the <strong>
                      problems
                    </strong>{' '}
                    that this business can solve. Don't need to enter the
                    solutions here, just the problems:
                  </div>
                }
                placeholderText='Enter the key problems this business is aiming to solve'
                emptyFieldText='No problems have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
                includeClassName='form-control w-75'
              />

              <BusinessDetailField
                fieldName='ProductsServicesSummary'
                fieldNameLabel='Products / Services Summary'
                dataField='products_services_summary'
                extraDetail={
                  <div className=''>
                    What are the top key products and / or services this
                    business produces?
                    <br />
                    Enter a brief outline of the key products / services this
                    business is offering:
                  </div>
                }
                placeholderText='Enter outline of key products / services'
                emptyFieldText='No products or services have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
                includeClassName='form-control w-75'
              />

              <BusinessDetailField
                fieldName='ExitStrategy'
                fieldNameLabel='Exit Strategy'
                dataField='exit_strategy'
                extraDetail={
                  <div className=''>
                    What plans or ideas are there for an exit to this business?
                    Can the business be ongoing cashflow, sold or just wound
                    down?
                    <br />
                    Enter a brief exit strategy:
                  </div>
                }
                placeholderText='Enter exit strategy'
                emptyFieldText='No exit strategy ideas have been entered yet.'
                isViewOnly={true}
                buttonText='Edit'
                businessDetail={this.props.businessDetail}
                linkedEntityKeys={this.props.linkedEntityKeys}
                inputType='textarea'
                includeClassName='form-control w-75'
              />
            </tbody>
          </Table>
        </div>

        <SlidingPane
          className='business-detail-pane'
          overlayClassName='pane-business-detail-sliding-pane'
          isOpen={this.state.isBusinessDetailPaneOpen}
          title='Business Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isBusinessDetailPaneOpen: false });
          }}>
          <BusinessDetailForm
            currentDashboardId={businessDetail.dashboard_id}
            selectedBusinessId={businessDetail.business_id}
            currentUserId={auth().currentUser.uid}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default BusinessDetails;
