import React, { Component } from 'react';
import { db } from '../../../services/firebase';

//Import dayjs for date and time
import dayjs from 'dayjs';

import 'react-rangeslider/lib/index.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  stringToBoolean,
  getDaysOfWeek,
} from '../../../helpers/globalFunctions';

const daysOfWeek = getDaysOfWeek();

export default class ActivityDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedEntityKeys: this.props.linkedEntityKeys,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingActivityDetailForm: false,
      activityDetail: {
        linked_entity_id: this.props.linkedEntityKeys['linked_entity_id'],
        short_name: '',
        description: '',
        days_of_week: {
          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
        },
        start_time: '',
        duration: '0',
        linked_goal: '',
        activity_steps: '',
        dashboard_display: false,
        deleted: false,
        purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      },
      isNewActivity: this.props.isNewActivity,
      goalsList: this.props.goalsList,
      filteredBusinessList: [],
    };
    this.myRef = React.createRef();
    this.handleActivityDetailFormChange = this.handleActivityDetailFormChange.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let data = {
      dashboard_display: stringToBoolean(
        this.state.activityDetail.dashboard_display
      ),
      linked_entity_id: this.state.activityDetail.linked_entity_id,
      short_name: this.state.activityDetail.short_name,
      description: this.state.activityDetail.description,
      days_of_week: this.state.activityDetail.days_of_week,
      start_time: this.state.activityDetail.start_time,
      duration: this.state.activityDetail.duration,
      linked_goal: this.state.activityDetail.linked_goal,
      activity_steps: this.state.activityDetail.activity_steps,
      deleted: this.state.activityDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted) {
      db.collection('dashboards')
        .doc(this.state.linkedEntityKeys['dashboard_id'])
        .collection('recycled')
        .add(data)
        .then(function (docRef) {
          //console.log('Document written with ID: ', docRef.id);
        })
        .catch(function (error) {
          //   console.error('Error adding document: ', error);
        });

      db.collection('dashboards')
        .doc(this.props.linkedEntityKeys['dashboard_id'])
        .collection('activities')
        .doc(this.state.activityDetail['key'])
        .delete()
        .then(function () {
          //console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          // console.error('Error removing document: ', error);
        });
    } else {
      if (data.short_name.length > 1) {
        if (this.state.isNewActivity) {
          await db
            .collection('dashboards')
            .doc(this.state.linkedEntityKeys['dashboard_id'])
            .collection('activities')
            .add(data)
            .then(function (docRef) {
              // console.log('Document written with ID: ', docRef.id);
            })
            .catch(function (error) {
              //  console.error('Error adding document: ', error);
            });
        } else {
          await db
            .collection('dashboards')
            .doc(this.props.linkedEntityKeys['dashboard_id'])
            .collection('activities')
            .doc(this.state.activityDetail['key'])
            .update(data);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingActivityDetailForm: true });

    if (this.props.businessesList) {
      let filteredBusinessList = this.props.businessesList.filter(
        (businessItem) => businessItem.status.includes('existing')
      );

      this.setState({ filteredBusinessList });
    }

    const newActivityDetail = {
      linked_entity_id: this.props.linkedEntityKeys['linked_entity_id'],
      short_name: '',
      description: '',
      days_of_week: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      start_time: '',
      duration: '',
      linked_goal: 'None',
      activity_steps: '',
      dashboard_display: false,
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewActivity) {
      this.setState({ activityDetail: newActivityDetail });
    } else {
      this.setState({ activityDetail: this.props.currentActivityDetail });
    }

    this.setState({ loadingActivityDetailForm: false });
  }

  async handleActivityDetailFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let activityDetail = { ...this.state.activityDetail };

    if (name === 'days_of_week') {
      activityDetail['days_of_week'][target.id] = target.checked;
    } else {
      activityDetail[name] = value;
    }

    this.setState({ activityDetail });
  }

  handleSaveActivityButton = () => {
    this.props.onActivitySaveDeleteButtonClicked(true);
  };

  handleDeleteActivityButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this activity?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let activityDetail = { ...this.state.activityDetail };
            activityDetail['deleted'] = true;
            activityDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ activityDetail });
            this.props.onActivitySaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const activityDetail = this.state.activityDetail;
    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingActivityDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='form_label' htmlFor='linked_entity_id'>
                  Link To
                </label>
                <select
                  className='form-control'
                  name='linked_entity_id'
                  onChange={this.handleActivityDetailFormChange}
                  value={
                    activityDetail.linked_entity_id
                      ? activityDetail.linked_entity_id
                      : this.props.linkedEntityKeys['linked_entity_id']
                  }>
                  {this.props.linkToOptions.map((item, key) => {
                    return (
                      <option key={key} value={item.key}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='business_name'>
                  Activity Short Name
                </label>
                <input
                  className='form-control'
                  placeholder='Activity Short Name'
                  name='short_name'
                  type='text'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.short_name || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='description'>
                  Description
                </label>
                <textarea
                  className='form-control'
                  placeholder='Description'
                  name='description'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.description || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='days_of_week'>
                  Days Of Week
                </label>
                <div className='weekDays-selector'>
                  {daysOfWeek.map((weekDay, key) => {
                    return (
                      <span key={key}>
                        <input
                          key={key}
                          name='days_of_week'
                          type='checkbox'
                          id={weekDay.obj_name}
                          className='weekday d-flex'
                          checked={
                            activityDetail['days_of_week'][weekDay.obj_name] ||
                            false
                          }
                          onChange={this.handleActivityDetailFormChange}
                        />
                        <label htmlFor={weekDay.obj_name}>
                          {weekDay.short_name}
                        </label>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className='form-group  w-25'>
                <label className='form_label' htmlFor='start_time'>
                  Start Time
                </label>
                <input
                  className='form-control'
                  placeholder='Start Time'
                  name='start_time'
                  type='time'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.start_time || ''}></input>
              </div>

              <div className='form-group w-25'>
                <label className='form_label' htmlFor='duration'>
                  Duration (Hrs)
                </label>
                <input
                  className='form-control'
                  placeholder='Duration'
                  name='duration'
                  type='text'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.duration || ''}></input>
              </div>

              <div className='form-group w-75'>
                <label className='form_label' htmlFor='linked_goal'>
                  Linked Goal
                </label>
                <select
                  className='form-control'
                  placeholder='Linked Goal'
                  name='linked_goal'
                  type='text'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.linked_goal || 'None'}>
                  <option value='None'>No linked Goal</option>
                  {this.props.goalsList.length > 0
                    ? this.props.goalsList.map((activityDetail, index) => {
                        return (
                          <option key={index} value={activityDetail.key}>
                            {activityDetail.short_name}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='activity_steps'>
                  Activity Steps
                </label>
                <textarea
                  className='form-control'
                  placeholder='Activity Steps'
                  name='activity_steps'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.activity_steps || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='dashboard_display'>
                  Display on Dashboard?
                </label>
                <select
                  readOnly={this.state.isViewMode}
                  className='form-control'
                  placeholder='Dashboard Display'
                  name='dashboard_display'
                  onChange={this.handleActivityDetailFormChange}
                  value={activityDetail.dashboard_display}>
                  <option key='0' value={false}>
                    No
                  </option>
                  <option key='1' value={true}>
                    Yes
                  </option>
                </select>
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleSaveActivityButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>

            <div
              onClick={this.handleDeleteActivityButton}
              className='small btn btn-danger mx-5'>
              Delete this Activity
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
