import React, { Component } from 'react';
import { db, auth } from '../../services/firebase';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getFirestoreTimestamp } from '../../helpers/db';

import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default class AdvisorMeetingDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorMeetingDetail: this.props.selectedAdvisorMeetingDetail,
      loadingAdvisorMeetingDetailForm: false,
      isNewAdvisorMeeting: this.props.isNewAdvisorMeeting,
      selectedList: [],
      clientsList: [],
      editMeetingDetails: false,
      openCloseButtonText: 'Open',
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    // release any snapshots

    if (this.state.advisorMeetingDetail.deleted) {
      const recycledRef = db
        .collection('dashboards')
        .doc(this.props.dashboardId)
        .collection('recycled/advisor_meetings/');

      const advisorMeetingsDBRef = db
        .collection('advisors')
        .doc(this.props.advisorDetail.id)
        .collection('meetings');

      //copy budget detail to recycled
      copyDoc(
        advisorMeetingsDBRef,
        this.props.selectedMeetingDetail['id'],
        recycledRef,
        {},
        false
      ).then(() => {
        this.state.dbMeetingDetailRef
          .doc(this.props.selectedMeetingDetail['id'])
          .delete();
      });
    } else {
      //     await this.createOrUpdateMeeting();
    }
  }

  async createOrUpdateMeeting() {
    const advisorMeetingsDBRef = db
      .collection('advisors')
      .doc(this.props.advisorDetail.id)
      .collection('meetings');

    // set meeting data
    let advisorMeeting = {
      date: this.state.advisorMeetingDetail.date,
      time: this.state.advisorMeetingDetail.time,
      meeting_title: this.state.advisorMeetingDetail.meeting_title,
      location: this.state.advisorMeetingDetail.location,
      zoom_link: this.state.advisorMeetingDetail.zoom_link,
      notes: this.state.advisorMeetingDetail.notes,
      status:
        this.state.advisorMeetingDetail.status === 'Reserved'
          ? 'Available'
          : this.state.advisorMeetingDetail.status,
      deleted: this.state.advisorMeetingDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      updated: getFirestoreTimestamp(),
      updated_by: auth().currentUser.uid,
    };

    if (advisorMeeting.date.length > 1 && advisorMeeting.time.length > 1) {
      if (this.state.isNewAdvisorMeeting) {
        await advisorMeetingsDBRef.add(advisorMeeting);
      } else {
        await advisorMeetingsDBRef
          .doc(this.props.selectedAdvisorMeetingDetail['id'])
          .update(advisorMeeting);
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingAdvisorMeetingDetailForm: true });

    const newAdvisorMeetingDetail = {
      date: dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('hh:mm'),
      meeting_title: '',
      location: '',
      zoom_link: '',
      notes: '',
      status: 'Available',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    /* if new meeting, then load state with blank meeting data, otherwise, 
    load the details from the props */
    if (this.props.isNewAdvisorMeeting) {
      this.setState({ advisorMeetingDetail: newAdvisorMeetingDetail });
    } else {
      this.setState({
        advisorMeetingDetail: this.props.selectedAdvisorMeetingDetail,
      });
    }

    this.setState({ loadingAdvisorMeetingDetailForm: false });
  }

  handleReserveButtonClick = () => {
    let advisorMeetingDetail = this.state.advisorMeetingDetail;
    advisorMeetingDetail['status'] = 'Reserved';
    this.setState({ advisorMeetingDetail });

    createOrUpdateMeeting();
  };

  handleClearReservationButtonClick = () => {
    let advisorMeetingDetail = this.state.advisorMeetingDetail;
    advisorMeetingDetail['status'] = 'Available';
    advisorMeetingDetail['client_list'] = [];

    this.setState({ advisorMeetingDetail });
    createOrUpdateMeeting();
  };

  handleAdvisorMeetingDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let advisorMeetingDetail = { ...this.state.advisorMeetingDetail };

    if (target.type === 'checkbox') {
      advisorMeetingDetail[name] = target.checked;
    } else {
      advisorMeetingDetail[name] = value;
    }
    this.setState({ advisorMeetingDetail });
  };

  handleAdvisorMeetingSaveButton = () => {
    this.props.onAdvisorMeetingSaveDeleteButtonClicked(true);
  };

  handleAdvisorMeetingDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this meeting?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let advisorMeetingDetail = { ...this.state.advisorMeetingDetail };
            advisorMeetingDetail['deleted'] = true;
            advisorMeetingDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ advisorMeetingDetail });
            this.props.onAdvisorMeetingSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleContactsRowClick = (row) => {
    const advisorMeetingDetail = this.state.advisorMeetingDetail;

    let newClientList = advisorMeetingDetail['client_list'];

    if (row.selected) {
      /* filter profile id out from from client_list array */
      newClientList = newClientList.filter((item) => item !== row.profile_id);
    } else {
      // dbREF.push(selectedContact);
      newClientList.push(row.profile_id);
    }
    advisorMeetingDetail['client_list'] = newClientList;
    this.setState({ advisorMeetingDetail });
  };

  handleOpenCloseEditFormToggle = () => {
    let editMeetingDetails = this.state.editMeetingDetails;
    editMeetingDetails = !editMeetingDetails;

    let openCloseButtonText = editMeetingDetails ? 'Close' : 'Open';

    this.setState({ editMeetingDetails, openCloseButtonText });
  };

  render() {
    const advisorMeetingDetail =
      this.state.advisorMeetingDetail ||
      this.props.selectedAdvisorMeetingDetail;

    /* get clients list and any selected clients in this meeting */
    const clientsList = this.props.clientsList;
    const selectedList = advisorMeetingDetail['client_list'];

    /* loop through clients and see if there is a matching entry 
    in the selected clients list */

    clientsList.forEach((clientItem, index) => {
      let filteredSelectedList = selectedList.find(
        (item) => item === clientItem.profile_id
      );

      if (filteredSelectedList) {
        clientsList[index].selected = true;
        clientsList[index].selected_key = filteredSelectedList.id;
      } else {
        clientsList[index].selected = false;
        clientsList[index].selected_key = '';
      }
    });

    const columns = [
      {
        dataField: 'selected',
        text: 'Selected',
        sort: true,
        style: { cursor: 'pointer' },
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No';
        },
        headerAttrs: {
          hidden: true,
        },
      },
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
        style: { cursor: 'pointer' },
        headerAttrs: {
          hidden: true,
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'display_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleContactsRowClick(row);
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const rowClasses = (row, rowIndex) => {
      if (row.selected) {
        return 'selected-contact-row-class';
      }
      return '';
    };

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingAdvisorMeetingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}
          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>

              <div
                onClick={this.handleOpenCloseEditFormToggle}
                className='small btn btn-success m-3'>
                {this.state.openCloseButtonText} Edit Form
              </div>
            </div>
            <React.Fragment>
              {/* Referrer Form START   */}
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <Table borderless className='table d-flex'>
                  <tbody>
                    {this.state.editMeetingDetails ? (
                      <React.Fragment>
                        <tr className=''>
                          <td>Meeting:</td>
                          <td>
                            <input
                              className='form-control'
                              placeholder='Date'
                              name='date'
                              type='date'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={advisorMeetingDetail.date || ''}></input>
                            <input
                              className='form-control w-100'
                              placeholder='Time'
                              name='time'
                              type='time'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={advisorMeetingDetail.time || ''}></input>
                          </td>
                          <td>Status:</td>
                          <td>
                            <select
                              className='form-control'
                              name='status'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={
                                advisorMeetingDetail.status || 'Available'
                              }>
                              <option value='Available'>Available</option>
                              <option value='Reserved'>Reserved</option>
                              <option value='Requested'>Requested</option>
                              <option value='Confirmed'>Confirmed</option>
                            </select>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Meeting Title:</td>
                          <td colSpan='4'>
                            <input
                              className='form-control'
                              placeholder='Meeting Title'
                              name='meeting_title'
                              type='text'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={
                                advisorMeetingDetail.meeting_title || ''
                              }></input>
                          </td>
                        </tr>
                        <tr>
                          <td>Location:</td>
                          <td colSpan='4'>
                            <input
                              className='form-control'
                              placeholder='Location'
                              name='location'
                              type='text'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={
                                advisorMeetingDetail.location || ''
                              }></input>
                          </td>
                        </tr>
                        <tr>
                          <td>Zoom Link:</td>
                          <td colSpan='4'>
                            <input
                              className='form-control'
                              placeholder='Zoom Link'
                              name='zoom_link'
                              type='text'
                              onChange={
                                this.handleAdvisorMeetingDetailFormChange
                              }
                              value={
                                advisorMeetingDetail.zoom_link || ''
                              }></input>
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <tr className=''>
                          <td>Meeting:</td>
                          <td>
                            <strong>
                              {dayjs(
                                advisorMeetingDetail.date +
                                  ' ' +
                                  advisorMeetingDetail.time
                              ).format('dddd D/M/YYYY h:mm a')}{' '}
                              (NSW)
                            </strong>
                          </td>
                          <td>Status:</td>
                          <td>{advisorMeetingDetail.status}</td>
                          <td>
                            {advisorMeetingDetail.status === 'Available' ? (
                              <div
                                onClick={this.handleReserveButtonClick}
                                className='small btn btn-sml btn-warning'>
                                Reserve
                              </div>
                            ) : (
                              ''
                            )}
                            {advisorMeetingDetail.status === 'Reserved' ||
                            advisorMeetingDetail.status === 'Requested' ? (
                              <div
                                onClick={this.handleClearReservationButtonClick}
                                className='small btn btn-sml btn-warning'>
                                Clear Reservation/Request
                              </div>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Meeting Title:</td>
                          <td colSpan='4'>
                            {advisorMeetingDetail.meeting_title ||
                              'No title provided yet'}
                          </td>
                        </tr>
                        <tr>
                          <td>Location:</td>
                          <td colSpan='4'>
                            {advisorMeetingDetail.location ||
                              'No location provided yet'}
                          </td>
                        </tr>
                        <tr>
                          <td>Zoom Link:</td>
                          <td colSpan='4'>
                            {advisorMeetingDetail.zoom_link ||
                              'No zoom link provided yet'}
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                    <tr>
                      <td>Notes:</td>
                      <td colSpan='4'>
                        <div className='form-group w-100'>
                          <textarea
                            className='form-control'
                            placeholder='Notes'
                            name='notes'
                            onChange={this.handleAdvisorMeetingDetailFormChange}
                            value={advisorMeetingDetail.notes || ''}></textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
                <h4>Add contacts:</h4>
                <BootstrapTable
                  noDataIndication='There are no contacts to display here at the moment'
                  classes='auto-table-layout'
                  keyField='key'
                  data={clientsList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  defaultSorted={defaultSorted}
                  rowEvents={rowEvents}
                  rowClasses={rowClasses}
                />
                <span className='small'>
                  Click "Reserve" to start adding someone to a meeting. The
                  meeting will automatically change to "Requested" once there is
                  one or more people added to this meeting.
                </span>
                <br />
                <div
                  onClick={this.handleAdvisorMeetingSaveButton}
                  className='small btn btn-success m-3'>
                  Save Changes
                </div>
                {
                  !this.props.isNewAdvisorMeeting ? (
                    <div
                      onClick={this.handleAdvisorMeetingDeleteButton}
                      className='small btn btn-danger m-3'>
                      Delete this Agent Meeting
                    </div>
                  ) : (
                    ''
                  ) /* end isNewAdvisorMeeting  */
                }
              </form>{' '}
            </React.Fragment>

            {/* Referrer Form END   */}
            <div className='form-group'>
              {this.state.error ? (
                <p className='text-danger'>{this.state.error}</p>
              ) : null}
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
