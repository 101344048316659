import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';

import { auth } from '../../../services/firebase';
import NumberFormat from 'react-number-format';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ProductServiceForm from './ProductServiceForm';

//const { ToggleList } = ColumnToggle;

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div className='d-flex ml-auto mt-3'>
    <div
      className='btn-group btn-group-toggle small ml-auto d-flex breakeven-selector d-flex'
      data-toggle='buttons'>
      <div className='mx-1'>
        Breakeven
        <br />
        Columns
      </div>
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) =>
          column.dataField.includes('_tbe') ? (
            <div key={'be' + column.dataField}>
              <input
                type='checkbox'
                id={column.dataField}
                key={'inputbe' + column.dataField}
                className='weekday '
                data-toggle='button'
                checked={column.toggle}
                onChange={() => onColumnToggle(column.dataField)}
              />

              <label htmlFor={column.dataField}>{column.text}</label>
            </div>
          ) : (
            ''
          )
        )}
    </div>

    <div
      className='btn-group btn-group-toggle small ml-auto d-flex targetGrossProfit-selector'
      data-toggle='buttons'>
      <div className='mx-1'>
        Target Gross
        <br /> Profit Columns
      </div>
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) =>
          column.dataField.includes('_tgp') ? (
            <div key={column.dataField}>
              <input
                type='checkbox'
                id={column.dataField}
                key={'tgp' + column.dataField}
                className='weekday '
                data-toggle='button'
                checked={column.toggle}
                onChange={() => onColumnToggle(column.dataField)}
              />

              <label htmlFor={column.dataField}>{column.text}</label>
            </div>
          ) : (
            ''
          )
        )}
    </div>
  </div>
);

class SettingTargets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      isProductServiceDetailPaneOpen: false,
      productsServicesList: this.props.productsServicesList,
      loadingSettingTargetsList: true,

      linkedEntityKeys: this.props.linkedEntityKeys,
      targetGrossProfitStyle: {
        backgroundColor: '#17a2b8',
        color: '#ffffff',
      },
      breakevenStyle: {
        backgroundColor: '#ffc107',
      },
    };
  }

  useEffect = () => {};

  async componentDidMount() {
    this.setState({
      loadingSettingTargetsList: false,
    });
    //  this.rebuildSettingTargetsList();
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleAddNewProductServiceButton = (event) => {
    let currentProductServiceItem = [];
    currentProductServiceItem['key'] = '';
    this.setState({
      currentProductServiceItem,
      isNewProductService: true,
      isProductServiceDetailPaneOpen: true,
    });
  };

  handleProductServiceSaveDeleteButtonClick = (value) => {
    this.setState({ isProductServiceDetailPaneOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'product_service_name',
        text: 'Product/Service',
        sort: true,
        hidden: false,
        footer: 'TOTALS',
      },

      {
        dataField: 'sell_price',
        text: 'Sell',
        sort: true,
        hidden: false,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'cost_price',
        text: 'Cost',
        sort: true,
        hidden: false,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'num_times',
        text: 'Qty',
        sort: false,
        hidden: false,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footer: '',
      },

      {
        dataField: 'daily_tbe',
        text: 'Day',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'weekly_tbe',
        text: 'Week',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'fortnightly_tbe',
        text: 'FN',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'monthly_tbe',
        text: 'Mon',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'quarterly_tbe',
        text: 'Qtr',
        sort: true,
        hidden: true,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'yearly_tbe',
        text: 'Year',
        sort: true,
        hidden: false,
        style: this.state.breakevenStyle,
        align: 'right',
        headerStyle: this.state.breakevenStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'target_amount',
        text: 'Target',
        sort: true,
        hidden: false,
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },

      {
        dataField: 'target_frequency',
        text: 'Per',
        sort: false,
        hidden: false,
        align: 'left',
        headerAlign: 'left',
        formatExtraData: {
          1: 'year',
          4: 'quarter',
          12: 'month',
          26: 'fortnight',
          52: 'week',
          365: 'day',
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return formatExtraData[cell];
        },
        footer: '',
      },

      {
        dataField: 'daily_tgp',
        text: 'Day',
        sort: true,
        hidden: true,
        style: this.state.targetGrossProfitStyle,
        align: 'right',
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'weekly_tgp',
        text: 'Week',
        sort: true,
        hidden: true,
        style: this.state.targetGrossProfitStyle,
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'fortnightly_tgp',
        text: 'FN',
        sort: true,
        hidden: true,
        style: this.state.targetGrossProfitStyle,
        align: 'right',
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'monthly_tgp',
        text: 'Mon',
        sort: true,
        hidden: true,
        style: this.state.targetGrossProfitStyle,
        align: 'right',
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'quarterly_tgp',
        text: 'Qtr',
        sort: true,
        hidden: true,
        style: this.state.targetGrossProfitStyle,
        align: 'right',
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'yearly_tgp',
        text: 'Year',
        sort: true,
        hidden: false,
        style: this.state.targetGrossProfitStyle,
        align: 'right',
        headerStyle: this.state.targetGrossProfitStyle,
        headerAlign: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footerAlign: 'right',
        footer: (columnData) => {
          let calcTotal = columnData.reduce(
            (acc, item) => parseFloat(acc) + parseFloat(item),
            0
          );
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'product_service_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentProductServiceItem: row,
          isNewProductService: false,
          isProductServiceDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div
        className='col products-services-section'
        ref={this.productsServicesAreaRef}>
        {this.state.loadingSettingTargetsList ? (
          <div
            className='spinner-border text-success loading-indicator'
            role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          <div>
            <div className='d-flex'>
              <div
                onClick={() => this.handleAddNewProductServiceButton()}
                className='btn btn-outline-dark ml-auto'>
                <span>Add New Product/Service</span>
              </div>
            </div>

            <ToolkitProvider
              keyField='key'
              data={this.props.productsServicesList}
              columns={columns}
              defaultSorted={defaultSorted}
              columnToggle>
              {(props) => (
                <div>
                  <CustomToggleList {...props.columnToggleProps} />

                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    bordered={false}
                    rowEvents={rowEvents}
                    noDataIndication='There are no products or services to display here at the moment'
                    classes='auto-table-layout'
                  />
                </div>
              )}
            </ToolkitProvider>

            <SlidingPane
              className='product-service-detail-pane'
              overlayClassName='pane-product-service-sliding-pane'
              isOpen={this.state.isProductServiceDetailPaneOpen}
              title='Product/Service Detail'
              subtitle=''
              width='85%'
              from='right'
              onRequestClose={() => {
                this.setState({ isProductServiceDetailPaneOpen: false });
              }}>
              <ProductServiceForm
                totalGrossProfitNeeded={this.props.totalGrossProfitNeeded}
                linkedEntityKeys={this.props.linkedEntityKeys}
                currentProductServiceItem={this.state.currentProductServiceItem}
                isNewProductService={this.state.isNewProductService}
                currentUserId={auth().currentUser.uid}
                onProductServiceSaveDeleteButtonClicked={() =>
                  this.handleProductServiceSaveDeleteButtonClick()
                }
              />
            </SlidingPane>
          </div>
        )}
      </div>
    );
  }
}

export default SettingTargets;
