import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import { auth } from '../../../services/firebase';
import { Table, Tabs, Tab } from 'react-bootstrap';

import BusinessExpenses from './BusinessExpenses';
import ProductsServices from './ProductsServices';
import SettingTargets from './SettingTargets';
import HowTo from './HowTo';

class TargetsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      key: 'expenses',
      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  setKey = (k) => {
    this.setState({ key: k });
  };

  render() {
    let totalGrossProfitNeeded =
      parseFloat(this.props.totalExpenses) +
      parseFloat(this.props.businessDetail.pay_yourself_first);

    let netDifference =
      this.props.totalTargetGrossProfit - totalGrossProfitNeeded;

    return (
      <div>
        <div className=''>
          <h4>
            My Brilliant <strong>Targets</strong>
          </h4>
          <i>Targets Summmary:</i>
          <br />

          <Table size='sm'>
            <thead></thead>
            <tbody>
              <tr>
                <td>Pay Yourself First:</td>
                <td>
                  <NumberFormat
                    decimalScale='2'
                    value={this.props.businessDetail.pay_yourself_first}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </td>
                <td>Total Business Expenses:</td>
                <td>
                  <NumberFormat
                    decimalScale='2'
                    value={this.props.totalExpenses}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan='2'>
                  <strong>Total Gross Profit Needed:</strong>
                </td>
                <td colSpan='2'>
                  <strong>
                    <NumberFormat
                      decimalScale='2'
                      value={totalGrossProfitNeeded}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan='2'>
                  <strong>Total Target Gross Profit Expected:</strong>
                </td>
                <td colSpan='2'>
                  <strong>
                    <NumberFormat
                      decimalScale='2'
                      value={this.props.totalTargetGrossProfit}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan='2'>
                  <strong>Difference:</strong>
                </td>
                <td colSpan='2'>
                  <strong>
                    <NumberFormat
                      decimalScale='2'
                      value={netDifference}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                    {' Net '} {netDifference < 0 ? 'Loss' : 'Profit'}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <hr />
        <Tabs
          id='business-targets-tabs'
          activeKey={this.state.key}
          onSelect={(k) => this.setKey(k)}>
          <Tab eventKey='expenses' title='Expenses'>
            <BusinessExpenses
              linkedEntityKeys={this.props.linkedEntityKeys}
              expensesList={this.props.expensesList}
            />
          </Tab>
          <Tab eventKey='productsServices' title='Products / Services'>
            <ProductsServices
              totalGrossProfitNeeded={totalGrossProfitNeeded}
              linkedEntityKeys={this.props.linkedEntityKeys}
              productsServicesList={this.props.productsServicesList}
            />
          </Tab>
          <Tab eventKey='targets' title='Targets'>
            <SettingTargets
              totalGrossProfitNeeded={totalGrossProfitNeeded}
              linkedEntityKeys={this.props.linkedEntityKeys}
              productsServicesList={this.props.productsServicesList}
            />
          </Tab>
          <Tab eventKey='targetsHelp' title='How To Set Targets'>
            <HowTo />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default TargetsMain;
