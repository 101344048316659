import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import BusinessDetailForm from './business/BusinessDetailForm';
import NumberFormat from 'react-number-format';
import { Table } from 'react-bootstrap';

import { auth } from '../../services/firebase';

class StrategySummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      businessDetail: this.props.businessDetail,

      isBusinessDetailPaneOpen: false,
    };
  }

  handleBusinessEditButtonClick = () => {
    this.setState({
      isBusinessDetailPaneOpen: true,
    });
  };

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    let businessDetail = this.props.businessDetail;
    let totalTargetGrossProfit = this.props.totalTargetGrossProfit;
    let grossProfitNeeded =
      parseFloat(this.props.businessDetail.pay_yourself_first) +
      parseFloat(this.props.totalExpenses);

    return (
      <div>
        <div className='d-flex'>
          <h4>Strategy Summary</h4>
          <div
            className='ml-auto mr-5 float-right btn btn-success'
            onClick={() => this.handleBusinessEditButtonClick()}>
            Edit Business Details
          </div>
        </div>
        <div className='table-responsive-sm'>
          <Table className='table d-flex'>
            <tbody>
              <tr className=''>
                <td>Review Date</td>
                <td>{businessDetail.review_date || 'No review date set'}</td>
              </tr>
              <tr>
                <td>Total Goals:</td>
                <td>{this.props.totalGoals}</td>
              </tr>
              <tr>
                <td>Total Activities Logged:</td>
                <td>{this.props.totalActivities}</td>
              </tr>
              <tr>
                <td>Targets Summary:</td>
                <td>
                  <Table size='sm' borderless>
                    <tbody>
                      <tr>
                        <td>Total Gross Profit Needed:</td>
                        <td align='right'>
                          <NumberFormat
                            decimalScale='2'
                            value={grossProfitNeeded}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Target Gross Profit Expected:</td>
                        <td align='right'>
                          <NumberFormat
                            decimalScale='2'
                            value={totalTargetGrossProfit}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Difference:</td>
                        <td align='right'>
                          <NumberFormat
                            decimalScale='2'
                            value={totalTargetGrossProfit - grossProfitNeeded}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          {totalTargetGrossProfit - grossProfitNeeded < 0
                            ? 'Net Loss'
                            : 'Net Profit'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <SlidingPane
          className='business-details-pane'
          overlayClassName='pane-details-sliding-pane'
          isOpen={this.state.isBusinessDetailPaneOpen}
          title='Business Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isBusinessDetailPaneOpen: false });
          }}>
          <BusinessDetailForm
            currentDashboardId={businessDetail.dashboard_id}
            selectedBusinessId={businessDetail.business_id}
            currentUserId={auth().currentUser.uid}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default StrategySummary;
