import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Service</strong>
        </h4>
        This section is currently being developed.
        <br />
        It will be about goals you want to achieve and what activities you can
        put in place to excel at customer service. The key questions to ask are:
        <br />
        -> What sort of things do you do to look after your existing
        clients/customers?
        <br />
        -> Can existing clients/customers refer people they know to you? <br />
        -> Are the any cross-sell opportunities?
        <br />
        -> What goals do you want to achievearound this?
        <br />
      </div>
    );
  }
}

export default Service;
