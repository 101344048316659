export const userProfile = {
  display_name: '',
  mobile_phone: '',
  email_address: '',
  address: '',
  approved_user: false,
  role_level: 'owner',
  profile_complete: false,
  uid: '',
  num_biz: 3,
  email_verified: '',
  portal_manager: false,
  is_advisor: false,
  account_level: 'New',
};

export const businessDetail = {
  business_name: '',
  description: '',
  exit_strategy: '',
  pay_yourself_first: '',
  purpose_why: '',
  status: 'new',
  start_date: '',
  review_date: '',
  notes: '',
  key_people: '',
  products_services_summary: '',
  problem_solved: '',
};

export const marketingOverview = {
  target_market: '',
  ideal_client: '',
  key_messages: '',
  elevator_pitch: '',
  prospect_journey: '',
  marketing_funnel: '',
  marketing_goals: '',
  marketing_activities: '',
};

/* export const userProfile = {
    display_name: user.displayName,
    mobile_phone: user.phoneNumber,
    email_address: user.email,
    address: '',
    approved_user: false,
    role_level: 'view',
    profile_complete: false,
    uid: user.uid,
    num_biz: 3,
    email_verified: user.emailVerified,
  };
*/
