import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { db } from '../../../services/firebase';

export default class BusinessDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbBusinessDetailRef: db
        .collection('dashboards')
        .doc(this.props.dashboardId)
        .collection('businesses')
        .doc(this.props.selectedBusinessDetail.key),
      readError: null,
      writeError: null,
      loadingBusinessDetailForm: false,
      businessDetail: this.props.selectedBusinessDetail,
      isAdditionalDetailsShowing: false,
      addtionalDetailsButtonText: 'More details...',
    };
    this.myRef = React.createRef();
    this.handleBusinessDetailChangeForm = this.handleBusinessDetailChangeForm.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdditionalDetailsButtonClick = this.handleAdditionalDetailsButtonClick.bind(
      this
    );
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let newStatus = 'new';

    if (this.state.businessDetail.business_name.length > 0) {
      newStatus = 'existing';
    }

    this.state.dbBusinessDetailRef
      .update({
        business_name: this.state.businessDetail.business_name,
        description: this.state.businessDetail.description,
        exit_strategy: this.state.businessDetail.exit_strategy,
        pay_yourself_first: this.state.businessDetail.pay_yourself_first,
        purpose_why: this.state.businessDetail.purpose_why,
        status: newStatus,
        start_date: this.state.businessDetail.start_date,
        review_date: this.state.businessDetail.review_date,
        notes: this.state.businessDetail.notes,
        key_people: this.state.businessDetail.key_people,
        products_services_summary: this.state.businessDetail
          .products_services_summary,
        problem_solved: this.state.businessDetail.problem_solved,
      })
      .then(function () {
        // console.log('Business Detail updated');
      })
      .catch(function (e) {
        console.error('There was an error: ', e);
      });
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingBusinessDetailForm: true });

    this.setState({
      loadingbusinessDetailForm: false,
    });
  }

  async handleBusinessDetailChangeForm(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let businessDetail = { ...this.state.businessDetail };
    businessDetail[name] = value;
    this.setState({ businessDetail });
  }

  handleAdditionalDetailsButtonClick() {
    let isAdditionalDetailsShowing = true;
    let addtionalDetailsButtonText = 'Hide extra details';

    if (this.state.isAdditionalDetailsShowing) {
      addtionalDetailsButtonText = 'More details...';
      isAdditionalDetailsShowing = false;
    }
    this.setState({
      isAdditionalDetailsShowing,
      addtionalDetailsButtonText,
    });
  }

  render() {
    const businessDetail = this.state.businessDetail;
    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingbusinessDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit details below. Changes will be made automatically. Close out
              of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='core-business-details'>
                <div className='form-group'>
                  <input
                    type='hidden'
                    className='form-control'
                    name='status'
                    value={businessDetail.status || 'new'}></input>
                  <label className='form_label' htmlFor='business_name'>
                    Business Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Business Name'
                    name='business_name'
                    type='text'
                    onChange={this.handleBusinessDetailChangeForm}
                    value={businessDetail.business_name || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='description'>
                    Description
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Description'
                    name='description'
                    onChange={this.handleBusinessDetailChangeForm}
                    value={businessDetail.description || ''}></textarea>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='start_date'>
                    Start Date
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Start Date'
                    name='start_date'
                    type='date'
                    onChange={this.handleBusinessDetailChangeForm}
                    value={businessDetail.start_date || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='review_date'>
                    Review Date
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Review Date'
                    name='review_date'
                    type='date'
                    onChange={this.handleBusinessDetailChangeForm}
                    value={businessDetail.review_date || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleBusinessDetailChangeForm}
                    value={businessDetail.notes || ''}></textarea>
                </div>
              </div>

              <Button
                className='btn float-sm-right'
                onClick={this.handleAdditionalDetailsButtonClick}>
                {this.state.addtionalDetailsButtonText}
              </Button>

              <div className='business-form-additional-details'>
                <hr />
                <Collapse
                  className=''
                  in={this.state.isAdditionalDetailsShowing}>
                  <div>
                    <div className='form-group'>
                      <label className='form_label' htmlFor='purpose_why'>
                        Purpose / Why
                      </label>
                      <textarea
                        className='form-control'
                        placeholder='Purpose / Why'
                        name='purpose_why'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={businessDetail.purpose_why || ''}></textarea>
                    </div>

                    <div className='form-group'>
                      <label
                        className='form_label'
                        htmlFor='pay_yourself_first'>
                        Pay Yourself First Amount
                      </label>
                      <input
                        className='form-control'
                        placeholder='Pay Yourself First Amount'
                        name='pay_yourself_first'
                        type='number'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={
                          businessDetail.pay_yourself_first || '0'
                        }></input>
                    </div>

                    <div className='form-group'>
                      <label className='form_label' htmlFor='key_people'>
                        Key People
                      </label>
                      <textarea
                        className='form-control'
                        placeholder='Who are the key people for this business - decision makers and advisors'
                        name='key_people'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={businessDetail.key_people || ''}></textarea>
                    </div>

                    <div className='form-group'>
                      <label className='form_label' htmlFor='problem_solved'>
                        What Problem Is This Business Addressing
                      </label>
                      <textarea
                        className='form-control'
                        placeholder='What Problem Is This Business Addressing'
                        name='problem_solved'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={businessDetail.problem_solved || ''}></textarea>
                    </div>

                    <div className='form-group'>
                      <label
                        className='form_label'
                        htmlFor='products_services_summary'>
                        Products/Services Summary
                      </label>
                      <textarea
                        className='form-control'
                        placeholder='Add a brief overview of the business products and/or services'
                        name='products_services_summary'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={
                          businessDetail.products_services_summary || ''
                        }></textarea>
                    </div>

                    <div className='form-group'>
                      <label className='form_label' htmlFor='exit_strategy'>
                        Exit Strategy
                      </label>
                      <textarea
                        className='form-control'
                        placeholder='Exit Strategy'
                        name='exit_strategy'
                        onChange={this.handleBusinessDetailChangeForm}
                        value={businessDetail.exit_strategy || ''}></textarea>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
