import React, { Component } from 'react';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { db } from '../../../services/firebase';
import { copyDoc } from '../../../helpers/globalFunctions';

export default class BudgetItemDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbBudgetItemDetailRef: db
        .collection('dashboards')
        .doc(this.props.linkedEntityKeys['dashboard_id'])
        .collection('personal')
        .doc(this.props.linkedEntityKeys['linked_entity_id'])
        .collection('budgets')
        .doc(this.props.selectedBudget.key)
        .collection('items'),
      selectedBudgetItemDetail: this.props.selectedBudgetItemDetail,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingBudgetItemDetailForm: false,
      budgetItemDetail: {
        item_name: '',
        item_type: 'DR',
        status: 'new',
        amount: '',
        num_times: '',
        notes: '',
        updated_dts: dayjs().toString(),
        delete: false,
        purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      },
    };
    this.myRef = React.createRef();
    this.handleBudgetItemDetailChangeform = this.handleBudgetItemDetailChangeform.bind(
      this
    );
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let budgetItemDetail = this.state.budgetItemDetail;

    let data = {
      item_name: budgetItemDetail.item_name ? budgetItemDetail.item_name : '',
      amount: budgetItemDetail.amount ? budgetItemDetail.amount : '0',
      num_times: budgetItemDetail.num_times ? budgetItemDetail.num_times : '0',
      item_type: budgetItemDetail.item_type ? budgetItemDetail.item_type : 'DR',
      notes: budgetItemDetail.notes ? budgetItemDetail.notes : '',
      updated_dts: dayjs().toString(),
      deleted: budgetItemDetail.deleted ? budgetItemDetail.deleted : false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted) {
      let recycledRef = db
        .collection('dashboards')
        .doc(this.props.currentDashboardId)
        .collection('recycled');

      //copy budget detail to recycled
      copyDoc(
        this.state.dbBudgetItemDetailRef,
        this.props.selectedBudgetItemDetail['key'],
        recycledRef,
        {},
        false
      ).then(() => {
        this.state.dbBudgetItemDetailRef
          .doc(this.props.selectedBudgetItemDetail['key'])
          .delete()
          .then(this.calcSummaryTotals());
      });
    } else {
      if (this.state.budgetItemDetail.item_name.length > 0) {
        if (this.props.isNewBudgetItem) {
          data['status'] = 'new';

          this.state.dbBudgetItemDetailRef
            .add(data)
            .then(this.calcSummaryTotals());
        } else {
          data['status'] = 'existing';
          await this.state.dbBudgetItemDetailRef
            .doc(this.props.selectedBudgetItemDetail['key'])
            .update(data)
            .then(this.calcSummaryTotals());
        }
      }
    }
  }

  calcSummaryTotals = () => {
    let data = [];

    // list all items for current budget
    db.collection('dashboards')
      .doc(this.props.linkedEntityKeys['dashboard_id'])
      .collection('personal')
      .doc(this.props.linkedEntityKeys['linked_entity_id'])
      .collection('budgets')
      .doc(this.props.selectedBudget.key)
      .collection('items')
      .get()
      .then((querySnapshot) => {
        let totalIncome = 0;
        let totalExpenses = 0;
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          let data = doc.data();
          let itemTotal =
            parseFloat(data['amount']) * parseFloat(data['num_times']);

          if (data['item_type'] === 'CR') {
            totalIncome += itemTotal;
          } else {
            totalExpenses += itemTotal;
          }
        });

        data = {
          total_income: totalIncome ? totalIncome : '0',
          total_expenses: totalExpenses ? totalExpenses : '0',
          inc_exp_diff: totalIncome - totalExpenses,
          updated_dts: dayjs().toString(),
        };

        db.collection('dashboards')
          .doc(this.props.linkedEntityKeys['dashboard_id'])
          .collection('personal')
          .doc(this.props.linkedEntityKeys['linked_entity_id'])
          .collection('budgets')
          .doc(this.props.selectedBudget.key)
          .update(data);

        return true;
      })
      .catch(function (e) {
        // console.error('There was an error: ', e);
        return false;
      });

    return false;
  };

  async componentDidMount() {
    this.setState({ readError: null, loadingBudgetItemDetailForm: true });

    if (!this.props.isNewBudgetItem) {
      this.setState({ budgetItemDetail: this.props.selectedBudgetItemDetail });
    }

    /* try {
      let budgetItemDetail = [];

      this.state.dbBudgetItemDetailRef.get().then((doc) => {
        const data = doc.data();

        budgetItemDetail = {
          item_name: data.item_name ? data.item_name : '',
          status: data.status ? data.status : 'new',
          amount: data.amount ? data.amount : '',
          num_times: data.num_times ? data.num_times : '',
          notes: data.notes ? data.notes : '',
          total_income: data.total_income ? data.total_income : '',
          total_expenses: data.total_expenses ? data.total_expenses : '',
          inc_exp_diff: data.inc_exp_diff ? data.inc_exp_diff : '',
        };

        this.setState({ loadingbudgetItemDetailForm: false });
      });
    } catch (error) {
      console.log('error', error);
      this.setState({ readError: error.message });
    }  */
  }

  async handleBudgetItemDetailChangeform(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let budgetItemDetail = { ...this.state.budgetItemDetail };
    budgetItemDetail[name] = value;
    this.setState({ budgetItemDetail });
  }

  handleSaveBudgetItemButton = () => {
    this.props.onBudgetItemSaveDeleteButtonClicked(true);
  };

  handleDeleteBudgetItemButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this budget item?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let budgetItemDetail = { ...this.state.budgetItemDetail };
            budgetItemDetail['deleted'] = true;
            budgetItemDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ budgetItemDetail });
            this.props.onBudgetItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const budgetItemDetail = this.state.budgetItemDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingbudgetItemDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit details below. Changes will be made automatically. Close out
              of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='budget-details'>
                <div className='form-group'>
                  <input
                    type='hidden'
                    className='form-control'
                    name='status'
                    value={budgetItemDetail.status || 'new'}></input>

                  <label className='form_label' htmlFor='item_type'>
                    Income/Expense
                  </label>

                  <select
                    className='form-control'
                    placeholder='Type'
                    name='item_type'
                    onChange={this.handleBudgetItemDetailChangeform}
                    value={budgetItemDetail.item_type || 'DR'}>
                    <option value='DR'>Expense</option>
                    <option value='CR'>Income</option>
                  </select>

                  <label className='form_label' htmlFor='item_name'>
                    Budget Item
                  </label>
                  <input
                    className='form-control'
                    placeholder='Budget Item Name'
                    name='item_name'
                    type='text'
                    onChange={this.handleBudgetItemDetailChangeform}
                    value={budgetItemDetail.item_name || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='amount'>
                    Amount
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Amount'
                    name='amount'
                    type='text'
                    onChange={this.handleBudgetItemDetailChangeform}
                    value={budgetItemDetail.amount}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='num_times'>
                    Qty
                  </label>
                  <input
                    className='form-control w-50'
                    placeholder='Qty or Number Times per year'
                    name='num_times'
                    type='text'
                    onChange={this.handleBudgetItemDetailChangeform}
                    value={budgetItemDetail.num_times}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleBudgetItemDetailChangeform}
                    value={budgetItemDetail.notes || ''}></textarea>
                </div>
              </div>
              <div
                onClick={this.handleSaveBudgetItemButton}
                className='small btn btn-success mx-5'>
                Save Changes
              </div>

              <div
                onClick={this.handleDeleteBudgetItemButton}
                className='small btn btn-danger mx-5'>
                Delete this Item
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
