import React, { Component } from 'react';
import { db } from '../../../services/firebase';

import { Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import dayjs from 'dayjs';

export default class BusinessExpenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbExpensesCollectionRef: db
        .collection('dashboards')
        .doc(this.props.linkedEntityKeys.dashboard_id)
        .collection('businesses')
        .doc(this.props.linkedEntityKeys.linked_entity_id)
        .collection('expenses'),
      selectedBusinessId: this.props.selectedBusinessId,
      currentDashboardId: this.props.currentDashboardId,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingBusinessExpenseForm: false,
      expenseDetail: this.props.currentExpenseItem,
      linkedEntityKeys: this.props.linkedEntityKeys,
      expenseTotal:
        parseFloat(this.props.currentExpenseItem.amount) *
        parseFloat(this.props.currentExpenseItem.num_times),
    };
    this.myRef = React.createRef();
    this.handleExpenseDetailChangeForm = this.handleExpenseDetailChangeForm.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentWillUnmount() {
    let data = {
      expense_name: this.state.expenseDetail.expense_name,
      amount: this.state.expenseDetail.amount,
      num_times: this.state.expenseDetail.num_times,
      notes: this.state.expenseDetail.notes,
      status: this.state.expenseDetail.status,
      deleted: this.state.expenseDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (data.deleted) {
      db.collection('dashboards')
        .doc(this.state.linkedEntityKeys['dashboard_id'])
        .collection('recycled')
        .add(data)
        .then(function (docRef) {
          //console.log('Document written with ID: ', docRef.id);
        })
        .catch(function (error) {
          //   console.error('Error adding document: ', error);
        });

      this.state.dbExpensesCollectionRef
        .doc(this.props.currentExpenseItem.key)
        .delete()
        .then(function () {
          //console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          // console.error('Error removing document: ', error);
        });
    } else {
      if (data.expense_name.length > 1) {
        if (this.props.isNewExpense) {
          await this.state.dbExpensesCollectionRef
            .add(data)
            .then(function (docRef) {
              //  console.log('Document written with ID: ', docRef.id);
            })
            .catch(function (error) {
              //   console.error('Error adding document: ', error);
            });
        } else {
          await this.state.dbExpensesCollectionRef
            .doc(this.props.currentExpenseItem.key)
            .update(data)
            .then(function () {
              // console.log('Business Detail updated');
            })
            .catch(function (e) {
              console.error('There was an error: ', e);
            });
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingBusinessExpenseForm: true });

    let data = {
      expense_name: '',
      amount: '',
      num_times: '',
      notes: '',
      status: '',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewExpense) {
      this.setState({ expenseDetail: data, expenseTotal: 0.0 });
    }
    this.setState({ loadingexpenseDetailForm: false });
  }

  async handleExpenseDetailChangeForm(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let expenseDetail = { ...this.state.expenseDetail };
    expenseDetail[name] = value;

    let expenseTotal =
      parseFloat(expenseDetail.amount ? expenseDetail.amount : 0.0) *
      parseFloat(expenseDetail.num_times ? expenseDetail.num_times : 0);
    this.setState({ expenseDetail, expenseTotal });
  }

  handleSaveExpenseButton = () => {
    this.props.onExpenseSaveDeleteButtonClicked(true);
  };

  handleDeleteExpenseButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this expense?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let expenseDetail = { ...this.state.expenseDetail };
            expenseDetail['deleted'] = true;
            expenseDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ expenseDetail });
            this.props.onExpenseSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const expenseDetail = this.state.expenseDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingexpenseDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit details below. Changes will be made automatically. Close out
              of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='core-business-details'>
                <div className='form-group'>
                  <input
                    type='hidden'
                    className='form-control'
                    name='status'
                    value={expenseDetail.status || 'active'}></input>
                  <label className='form_label' htmlFor='expense_name'>
                    Expense Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Expense Name'
                    name='expense_name'
                    type='text'
                    onChange={this.handleExpenseDetailChangeForm}
                    value={expenseDetail.expense_name || ''}></input>
                </div>
                <div className='d-flex'>
                  <div className='form-group'>
                    <label className='form_label' htmlFor='amount'>
                      Amount
                    </label>
                    <input
                      className='form-control'
                      placeholder='0.00'
                      name='amount'
                      type='number'
                      onChange={this.handleExpenseDetailChangeForm}
                      value={expenseDetail.amount || ''}></input>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='num_times'>
                      Number of Times
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='0'
                      name='num_times'
                      onChange={this.handleExpenseDetailChangeForm}
                      value={expenseDetail.num_times || ''}></input>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleExpenseDetailChangeForm}
                    value={expenseDetail.notes || ''}></textarea>
                </div>
              </div>
              <hr />
              <div className=''>
                <h5>Expense Calculations</h5>
                <div className=''>
                  <Table className='no-borders' hover>
                    <thead>
                      <tr className=''>
                        <td>Daily</td>
                        <td>Weekly</td>
                        <td>Fortnightly</td>
                        <td>Monthly</td>
                        <td>Quarterly</td>
                        <td>Yearly</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className=''>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal / 365}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal / 52}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal / 26}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal / 12}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal / 4}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            decimalScale='2'
                            value={this.state.expenseTotal}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div
                  onClick={this.handleSaveExpenseButton}
                  className='small btn btn-success mx-5'>
                  Save Changes
                </div>

                <div
                  onClick={this.handleDeleteExpenseButton}
                  className='small btn btn-danger mx-5'>
                  Delete this Expense
                </div>
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
