import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class TargetsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div>
        <p>&nbsp;</p>
        <p>
          Once your targets are set, you are in a much better position to make
          decisions for your business in relation to marketing and sales.{' '}
        </p>

        <p>&nbsp;</p>
        <h4>Overview:</h4>
        <p>
          Here are the key steps to <strong>Setting Targets</strong> for this
          business:
        </p>
        <ol>
          <li>
            <strong>
              How much do you want this business to pay you over the next 12
              months?
            </strong>
            <br />
            This is what is referred to as "Pay Yourself First" and is an
            important part for calculating how much work needs to be done to put
            money in your pocket and run the business.
            <br />
            Coming soon is a "Personal Budgeting Tool" to assist with working
            out how much your business needs to make.
          </li>
          <br />
          <li>
            <strong>
              What are the business expenses for the next 12 months
            </strong>
            <br />
            If you have already had business expenses, you should be able to
            bring that information into here. It can either be summarised or
            entered in detail.
            <br />
            If you don't have existing business expenses, you can discusss with
            your accountant or bookkeeper the likely expenses your business will
            need over the next 12 months.
            <br />
            The expenses section here makes it easy to calculate business
            expenses with simply entering the amount of the expense and then the
            Quantity (Qty) - how many times that expense will occur over a 12
            month period.
            <br />
            The built in calculations work out the total amount for the year and
            then shows you a breakdown of each expense per day, week, fortnight,
            month, quarter, year.
            <br /> The combination of paying yourself first and the total
            business expenses will calculate the Total Gross Profit Needed. This
            is used to calculate how much of your products / services will need
            to be sold
            <br />
            Coming soon is a list of common business expenses for small business
            that you can work from.
          </li>
          <br />
          <li>
            <strong>
              What are the products and services for this business
            </strong>
            <br />
            Enter each of the products or services this business can offer. Here
            you can add your sell price, cost price and how many times each
            product or service will sell, eg many products or services are a one
            off so the quantity will be "1", some products or services are
            ongoing eg monthly subscriptions so the quantity might be "12"
            <br />
            If there are many products/services, try to look at averages or some
            other way to summarise and represent your products and services
            <br /> The outcome of this process will be a breakeven number of
            products or services that need to be sold to achieve the goal of
            paying you and running the business.
            <br /> Coming soon will be advance pricing tool to help really work
            our pricing wihtin the portal.
          </li>
          <br />
          <li>
            <strong>Set the targets</strong>
            <br />
            For each of the products or services, enter a target amount that you
            think will be achievable for selling over the next 12 months.
            <br />
            The outcome will show you the gross profit each product/service will
            generate over the next 12 months.
          </li>
        </ol>
      </div>
    );
  }
}

export default TargetsMain;
