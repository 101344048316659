import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import ActivityDetailForm from '../activities/ActivityDetailForm';

import { auth } from '../../../services/firebase';

import { getDaysOfWeek } from '../../../helpers/globalFunctions';

const daysOfWeek = getDaysOfWeek();

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      isActivityDetailPaneOpen: false,
      currentActivityDetail: [],
      activitiesList: this.props.activitiesList,
      linkedEntityKeys: this.props.linkedEntityKeys,
      isBusinessPersonalColHidden: true,
    };

    this.handleAddNewActivityButton = this.handleAddNewActivityButton.bind(
      this
    );
  }

  handleAddNewActivityButton = () => {
    this.setState({ isNewActivity: true, isActivityDetailPaneOpen: true });
  };

  async componentDidMount() {
    if (this.props.linkedEntityKeys['source'] === 'dashboard') {
      this.setState({ isBusinessPersonalColHidden: false });
    }
    this.setState({ linkedEntityKeys: this.props.linkedEntityKeys });
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleActivitySaveDeleteButtonClick = (value) => {
    this.setState({ isActivityDetailPaneOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'linked_entity_id',
        text: 'Business/Personal',
        formatter: (cell) => {
          let filteredBusiness = this.props.businessesList.filter(
            (businessItem) => businessItem.key.includes(cell)
          );
          if (
            filteredBusiness[0] &&
            filteredBusiness[0].business_name.length > 1
          ) {
            return filteredBusiness[0].business_name;
          }
          return 'Personal';
        },
        sort: true,
        hidden: this.props.isBusinessPersonalColHidden,
      },
      {
        dataField: 'short_name',
        text: 'Activity',
        sort: true,
      },
      {
        dataField: 'days_of_week',
        text: 'Day(s)',
        formatter: (cell) => {
          const selectedDays = daysOfWeek
            .filter((item) => {
              return Object.keys(cell)
                .map((key) => [key, cell[key]])
                .filter((x) => x[1])
                .some((x) => x[0].includes(item.obj_name));
            })
            .map((dayShortName) => {
              return dayShortName.short_name;
            })
            .join(', ');

          if (selectedDays.length > 0) {
            return selectedDays;
          } else {
            return 'No days selected';
          }
        },
        sort: true,
      },
      {
        dataField: 'start_time',
        text: 'Start Time',
        sort: true,
      },
      {
        dataField: 'duration',
        text: 'Duration',
        formatter: (cell) => {
          return cell > 0 ? <span>{cell} hr(s)</span> : <span> -- </span>;
        },
        sort: true,
      },
      {
        dataField: 'linked_goal_name',
        text: 'Linked Goal',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'day_of_week',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          currentActivityDetail: row,
          isNewActivity: false,
          isActivityDetailPaneOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div>
        <div className='d-flex'>
          <h4>My Brilliant Activities</h4>
          <div
            onClick={() => this.handleAddNewActivityButton()}
            className='btn btn-outline-dark ml-auto'>
            <span>Add New Activity</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no activities to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={this.props.activitiesList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='activity-detail-pane'
          overlayClassName='pane-activity-sliding-pane'
          isOpen={this.state.isActivityDetailPaneOpen}
          title='Activity Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isActivityDetailPaneOpen: false });
          }}>
          <ActivityDetailForm
            businessesList={this.props.businessesList}
            isBusinessPersonalColHidden={this.state.isBusinessPersonalColHidden}
            goalsList={this.props.goalsList}
            currentActivityDetail={this.state.currentActivityDetail}
            linkedEntityKeys={this.props.linkedEntityKeys}
            linkToOptions={this.props.linkToOptions}
            isNewActivity={this.state.isNewActivity}
            currentUserId={auth().currentUser.uid}
            onActivitySaveDeleteButtonClicked={() =>
              this.handleActivitySaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default Activities;
