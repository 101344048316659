import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import NumberFormat from 'react-number-format';
import { Container, Row, Col, Table } from 'react-bootstrap';

import BudgetDetailForm from './BudgetDetailForm';
import BudgetItemDetailForm from './BudgetItemDetailForm';

import { auth } from '../../../services/firebase';
import { db } from '../../../services/firebase';

import dayjs from 'dayjs';

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div className='d-flex ml-auto mt-3'>
    <div
      className='btn-group btn-group-toggle small ml-auto d-flex breakeven-selector d-flex'
      data-toggle='buttons'>
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) =>
          index > 2 ? (
            <div key={column.dataField}>
              <input
                type='checkbox'
                id={column.dataField}
                key={column.dataField}
                className='weekday '
                data-toggle='button'
                checked={column.toggle}
                onChange={() => {
                  onColumnToggle(column.dataField);
                }}
              />

              <label htmlFor={column.dataField}>{column.text}</label>
            </div>
          ) : (
            ''
          )
        )}
    </div>
  </div>
);

class PersonalBudgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      isBudgetDetailPaneOpen: false,
      isBudgetItemDetailPaneOpen: false,
      linkedEntityKeys: this.props.linkedEntityKeys,
      isNewBudget: true,
      isNewBudgetItem: true,
      budgetsListView: true,
      totalIncome: 0,
      totalExpenses: 0,
      budgetsList: this.props.budgetsList,
      budgetViewItemsList: [],
      selectedBudgetItem: [],
    };
  }

  async loadBudgetItems(dashboard_id, linked_entity_key, budget_key) {
    await db
      .collection('dashboards')
      .doc(dashboard_id)
      .collection('personal')
      .doc(linked_entity_key)
      .collection('budgets')
      .doc(budget_key)
      .collection('items')
      .onSnapshot((snapshot) => {
        let totalIncome = 0;
        let totalExpenses = 0;
        let budgetItems = [];
        snapshot.forEach((doc) => {
          let data = doc.data();
          data['key'] = doc.id;

          let itemTotal =
            parseFloat(data['amount']) * parseFloat(data['num_times']);

          if (data['item_type'] === 'CR') {
            data['daily'] = itemTotal / 365;
            data['weekly'] = itemTotal / 52;
            data['fortnightly'] = itemTotal / 26;
            data['monthly'] = itemTotal / 12;
            data['quarterly'] = itemTotal / 4;
            data['yearly'] = itemTotal;

            totalIncome += itemTotal;
          } else {
            data['daily'] = itemTotal / 365;
            data['weekly'] = itemTotal / 52;
            data['fortnightly'] = itemTotal / 26;
            data['monthly'] = itemTotal / 12;
            data['quarterly'] = itemTotal / 4;
            data['yearly'] = itemTotal;

            totalExpenses += itemTotal;
          }

          budgetItems.push(data);
        });
        this.setState({ budgetItems, totalIncome, totalExpenses });
      });
  }

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  handleNewBudgetButtonClick = () => {
    this.setState({ isBudgetDetailPaneOpen: true, isNewBudget: true });
  };

  handleNewBudgetItemButtonClick = () => {
    this.setState({ isBudgetItemDetailPaneOpen: true, isNewBudgetItem: true });
  };

  handleReturnToBudgetsList = () => {
    this.setState({ budgetsListView: true });
  };

  handleBudgetSaveDeleteButtonClick = (value) => {
    this.setState({ isBudgetDetailPaneOpen: false });
  };

  handleBudgetItemSaveDeleteButtonClick = (value) => {
    this.setState({ isBudgetItemDetailPaneOpen: false });
  };

  handleBudgetSelected = (row) => {
    /*    let budgetViewItemsList = this.loadBudgetItems(
      this.props.linkedEntityKeys['dashboard_id'],
      this.props.linkedEntityKeys['linked_entity_id'],
      row.key
    );  */
    this.loadBudgetItems(
      this.props.linkedEntityKeys['dashboard_id'],
      this.props.linkedEntityKeys['linked_entity_id'],
      row.key
    );

    this.setState({
      selectedBudget: row,
      budgetsListView: false,
      isNewBudget: false,
    });
  };

  render() {
    let budgetViewItemsList = this.state.budgetItems;

    let incomeList = [];
    let expenseList = [];

    if (budgetViewItemsList && budgetViewItemsList.length > 0) {
      incomeList = budgetViewItemsList.filter((budgetItem) =>
        budgetItem.item_type.includes('CR')
      );

      expenseList = budgetViewItemsList.filter((budgetItem) =>
        budgetItem.item_type.includes('DR')
      );
    }
    /*  start budgets list table setup */
    const columns = [
      {
        dataField: 'budget_name',
        text: 'Budget Title',
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.handleBudgetSelected(row);
          },
        },
      },

      {
        dataField: 'review_date',
        text: 'Review Date',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.handleBudgetSelected(row);
          },
        },
      },
      {
        dataField: 'total_income',
        text: 'Inc',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'total_expenses',
        text: 'Exp',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },

      {
        dataField: 'inc_exp_diff',
        text: 'Diff',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'edit_col',
        isDummyfield: true,
        text: 'Edit',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.setState({
              selectedBudget: row,
              isNewBudget: false,
              isBudgetDetailPaneOpen: true,
            });
          },
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div key={rowIndex} className='btn btn-sml btn-outline-dark'>
              Edit
            </div>
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'budget_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // do nothing at the moment.  each cell has a click option
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };
    /*  end budgets list table setup */

    const defaultItemSorted = [
      {
        dataField: 'item_name',
        order: 'asc',
      },
    ];

    /* start income columns setup */
    const incomeCols = [
      {
        dataField: 'item_name',
        text: 'Income',
        sort: true,
        hidden: false,
        toggle: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: 'TOTALS',
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        align: 'right',
        hidden: false,
        toggle: true,
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: '',
      },
      {
        dataField: 'num_times',
        text: 'Qty',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        hidden: false,
        toggle: true,
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: '',
      },
      {
        dataField: 'daily',
        text: 'Day',
        sort: true,
        hidden: true,
        toggle: false,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 365;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'weekly',
        text: 'Week',
        sort: true,
        hidden: true,
        toggle: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 52;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'fortnightly',
        text: 'FN',
        sort: true,
        hidden: true,
        toggle: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 26;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'monthly',
        text: 'Mon',
        sort: true,
        hidden: true,
        toggle: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 12;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'quarterly',
        text: 'Qtr',
        sort: true,
        hidden: true,
        toggle: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 4;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'yearly',
        text: 'Year',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        hidden: false,
        toggle: true,
        formatter: (cell, row) => {
          let calcTotal = row.amount * row.num_times;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
    ];

    const incomeRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          isBudgetItemDetailPaneOpen: true,
          isNewBudgetItem: false,
          selectedBudgetItem: row,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    /* end income columns setyup */

    /* start expense columns setup */

    const expenseCols = [
      {
        dataField: 'item_name',
        text: 'Expenses',
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: 'TOTALS',
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: '',
      },
      {
        dataField: 'num_times',
        text: 'Qty',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          return (
            <NumberFormat
              decimalScale='2'
              value={cell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        footer: '',
      },
      {
        dataField: 'daily',
        text: 'Day',
        sort: true,
        hidden: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 365;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'weekly',
        text: 'Week',
        sort: true,
        hidden: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 52;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'fortnightly',
        text: 'FN',
        sort: true,
        hidden: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',

        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 26;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'monthly',
        text: 'Mon',
        sort: true,
        hidden: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 12;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'quarterly',
        text: 'Qtr',
        sort: true,
        hidden: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        formatter: (cell, row) => {
          let calcTotal = (row.amount * row.num_times) / 4;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
      {
        dataField: 'yearly',
        text: 'Year',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',

        hidden: false,
        formatter: (cell, row) => {
          let calcTotal = row.amount * row.num_times;
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
        footer: (columnData) => {
          let calcTotal = columnData.reduce((acc, item) => acc + item, 0);
          return (
            <NumberFormat
              decimalScale='2'
              value={calcTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          );
        },
      },
    ];

    const expenseRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          isBudgetItemDetailPaneOpen: true,
          isNewBudgetItem: false,
          selectedBudgetItem: row,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    /* end expense columns setup */
    return (
      <React.Fragment>
        {this.state.budgetsListView ? (
          <React.Fragment>
            <div className='d-flex'>
              <h4>Budgets</h4>
              <div
                className='ml-auto mr-5 btn-sml float-right btn btn-success'
                onClick={this.handleNewBudgetButtonClick}>
                Add New Budget
              </div>
            </div>
            <div className='table-responsive-sm'>
              <BootstrapTable
                noDataIndication='There are no budgets to display here at the moment'
                classes='auto-table-layout'
                keyField='key'
                data={this.props.budgetsList}
                columns={columns}
                striped
                hover
                condensed
                bordered={false}
                defaultSorted={defaultSorted}
                rowEvents={rowEvents}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className='buget-items-section'>
            <React.Fragment>
              <div
                onClick={this.handleReturnToBudgetsList}
                className='btn btn-sml btn-outline-dark'>
                Return to Budgets List
              </div>
              <br />

              <Table size='sm'>
                <tbody>
                  <tr>
                    <td>Budget Name</td>
                    <td>{this.state.selectedBudget.budget_name}</td>
                    <td>Review Date</td>
                    <td>{this.state.selectedBudget.review_date}</td>
                  </tr>
                  <tr>
                    <td>Last Updated</td>
                    <td colSpan='2'>{this.state.selectedBudget.updated_dts}</td>
                  </tr>
                  <tr>
                    <td colSpan='4'>
                      <Table borderless>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>
                              <NumberFormat
                                decimalScale='2'
                                value={this.state.totalIncome}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>
                              <NumberFormat
                                decimalScale='2'
                                value={this.state.totalExpenses}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total Difference</td>
                            <td>
                              <NumberFormat
                                decimalScale='2'
                                value={
                                  this.state.totalIncome -
                                  this.state.totalExpenses
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className='ml-auto table-responsive-sm'>
                <div
                  className='mr-5 btn-sml btn btn-success'
                  onClick={this.handleNewBudgetItemButtonClick}>
                  Add New Budget Item
                </div>
              </div>

              <div className='table-responsive-sm'>
                <ToolkitProvider
                  keyField='key'
                  columns={incomeCols}
                  data={incomeList}
                  defaultSorted={defaultItemSorted}
                  columnToggle>
                  {(props) => (
                    <React.Fragment>
                      <div>
                        <CustomToggleList {...props.columnToggleProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          columns={incomeCols}
                          data={incomeList}
                          noDataIndication='There are no income items to display here at the moment'
                          classes='auto-table-layout small'
                          striped
                          hover
                          condensed
                          bordered={false}
                          rowEvents={incomeRowEvents}
                          defaultSorted={defaultItemSorted}
                        />
                      </div>
                      <hr />
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          data={expenseList}
                          columns={expenseCols}
                          noDataIndication='There are no expense items to display here at the moment'
                          classes='auto-table-layout small'
                          striped
                          hover
                          condensed
                          bordered={false}
                          rowEvents={expenseRowEvents}
                          defaultSorted={defaultItemSorted}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>

              <div className='table-responsive-sm'></div>
            </React.Fragment>
          </div>
        )}

        <SlidingPane
          className='budget-details-pane'
          overlayClassName='pane-details-sliding-pane'
          isOpen={this.state.isBudgetDetailPaneOpen}
          title='Budget Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isBudgetDetailPaneOpen: false });
          }}>
          <BudgetDetailForm
            currentDashboardId={this.props.linkedEntityKeys['dashboard_id']}
            personalStrategyKey={
              this.props.linkedEntityKeys['linked_entity_id']
            }
            selectedBudgetDetail={this.state.selectedBudget}
            isNewBudget={this.state.isNewBudget}
            currentUserId={auth().currentUser.uid}
            onBudgetSaveDeleteButtonClicked={() =>
              this.handleBudgetSaveDeleteButtonClick()
            }
          />
        </SlidingPane>

        <SlidingPane
          className='budget-item-details-pane'
          overlayClassName='pane-details-sliding-pane'
          isOpen={this.state.isBudgetItemDetailPaneOpen}
          title='Budget Item Details'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isBudgetItemDetailPaneOpen: false });
          }}>
          <BudgetItemDetailForm
            linkedEntityKeys={this.props.linkedEntityKeys}
            selectedBudget={this.state.selectedBudget}
            selectedBudgetItemDetail={this.state.selectedBudgetItem}
            isNewBudgetItem={this.state.isNewBudgetItem}
            currentUserId={auth().currentUser.uid}
            onBudgetItemSaveDeleteButtonClicked={() =>
              this.handleBudgetItemSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </React.Fragment>
    );
  }
}

export default PersonalBudgets;
