import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Communication and Leadership</strong>
        </h4>
        This section is currently being developed.
        <br />
        It will be about goals you want to achieve and what activities you can
        put in place to improve your communication and leadership skills. The
        key questions to ask are:
        <br />
        -> Do you know your personality profile (Myers Briggs, DiSC, Wealth
        Dynamics)?
        <br />
        -> Are you aware of your emotional intelligence? <br />
        -> How do you plan to lead your business?
        <br />
        -> What goals do you want to achievearound this?
        <br />
      </div>
    );
  }
}

export default Communication;
