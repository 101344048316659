import React, { Component } from 'react';

import { auth } from '../../../services/firebase';

class Processes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
      },
      expensesList: this.props.expensesList,
      productsServicesList: this.props.productsServicesList,

      linkedEntityKeys: this.props.linkedEntityKeys,
      totalExpenses: this.props.totalExpenses,
      totalTargetGrossProfit: this.props.totalTargetGrossProfit,
    };
  }

  async componentDidMount() {
    // console.log(this.props.linkedEntityKeys, this.state.dbBusinessDetailRef);
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  render() {
    return (
      <div>
        <h4>
          My Brilliant <strong>Processes and Systems</strong> Strategy
        </h4>
        This section is currently being developed.
        <br />
        It will be about goals you want to achieve and what activities you can
        put in place to improve the business compliance and operations. The key
        questions to ask are:
        <br />
        -> What processes are in place to run the business?
        <br />
        -> What systems are being used to assist the operations of the business?
        <br />
        -> What goals do you want to achievearound this?
        <br />
      </div>
    );
  }
}

export default Processes;
