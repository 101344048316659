import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';

import { db } from '../../../services/firebase';

class BusinessDetailField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: this.props.fieldName,
      fieldNameLabel: this.props.fieldNameLabel,
      dataField: this.props.dataField,
      extraDetail: this.props.extraDetail,
      placeholderText: this.props.placeholderText,
      emptyFieldText: this.props.emptyFieldText,
      isViewOnly: this.props.isViewOnly,
      buttonText: this.props.buttonText,
      businessDetail: this.props.businessDetail,
      dbBusinessDetailRef: db
        .collection('dashboards')
        .doc(this.props.linkedEntityKeys['dashboard_id'])
        .collection('businesses')
        .doc(this.props.linkedEntityKeys['linked_entity_id']),
    };
  }

  async componentDidMount() {
    //  this.setState({ readExposListError: null, loadingExposList: true });
  }

  toggleExtraDetails = () => {
    let showExtraDetails = !this.state.isShowExtraDetails;
    this.setState({
      isShowExtraDetails: showExtraDetails,
    });
  };

  toggleViewMode = () => {
    if (!this.state.isViewOnly) {
      this.state.dbBusinessDetailRef
        .update({
          [this.props.dataField]: this.state.businessDetail[
            this.props.dataField
          ],
        })
        .then(function () {
          // console.log('Business Detail updated');
        })
        .catch(function (e) {
          //  console.error('There was an error: ', e);
        });
    }

    let newViewMode = !this.state.isViewOnly;
    let buttonText = newViewMode ? 'Edit' : 'Save';

    this.setState({
      businessDetail: this.props.businessDetail,
      isViewOnly: newViewMode,
      buttonText: buttonText,
    });
  };

  handleBusinessDetailChangeForm = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let businessDetail = { ...this.state.businessDetail };
    businessDetail[name] = value;
    this.setState({ businessDetail });
  };

  renderFormControl = (inputType) => {
    switch (inputType) {
      case 'textarea':
        return (
          <textarea
            readOnly={this.state.isViewOnly}
            className={this.props.includeClassName}
            placeholder={this.props.placeholderText}
            name={this.props.dataField}
            onChange={this.handleBusinessDetailChangeForm}
            value={
              this.state.businessDetail[this.props.dataField] || ''
            }></textarea>
        );

      default:
        return (
          <input
            type={this.props.inputType}
            readOnly={this.state.isViewOnly}
            className={this.props.includeClassName}
            placeholder={this.props.placeholderText}
            name={this.props.dataField}
            onChange={this.handleBusinessDetailChangeForm}
            value={
              this.state.businessDetail[this.props.dataField] || ''
            }></input>
        );
    }
  };

  render() {
    let displayContent =
      this.props.businessDetail[this.props.dataField] ||
      this.props.emptyFieldText;

    if (this.props.inputType === 'number') {
      const numberType = this.props.numberType;
      if (numberType) {
        let formatter = new Intl.NumberFormat('en-AU', {
          style: numberType,
          currency: 'AUD',
        });
        displayContent = formatter.format(displayContent);
      }
    }

    return (
      <tr>
        <td>
          <div className='form-group'>
            <label
              onClick={() => this.toggleExtraDetails()}
              className='form_label'
              htmlFor={this.props.dataField}>
              <strong>{this.props.fieldNameLabel}</strong>
              <span className='small'>&nbsp;(expand)</span>
            </label>

            <Collapse in={this.state.isShowExtraDetails}>
              <div className='small'>{this.props.extraDetail}</div>
            </Collapse>

            {this.state.isViewOnly ? (
              <div className=''>{displayContent}</div>
            ) : (
              this.renderFormControl(this.props.inputType)
            )}

            <div
              className='btn-sml ml-auto mr-5 float-right btn btn-success'
              onClick={() => this.toggleViewMode()}>
              {this.state.buttonText} {this.props.fieldNameLabel}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default BusinessDetailField;
