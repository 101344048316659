import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import AdvisorMeetingDetailForm from './AdvisorMeetingDetailForm';

import dayjs from 'dayjs';

class AdvisorMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdvisorMeetingDetailFormOpen: false,
      clientsList: this.props.clientsList,
      advisorMeetingsList: [],
    };
  }

  handleAddNewAdvisorMeetingButton = () => {
    this.setState({
      isNewAdvisorMeeting: true,
      isAdvisorMeetingDetailFormOpen: true,
    });
  };

  handleAdvisorMeetingRowClicked = (advisorMeetingId) => {
    this.props.onAdvisorMeetingRowClicked(advisorMeetingId);
  };

  componentDidMount() {}

  handleAdvisorMeetingSaveDeleteButtonClick = (value) => {
    this.setState({ isAdvisorMeetingDetailFormOpen: false });
  };

  render() {
    const clientsList = this.props.clientsList;
    const meetingsList = this.props.meetingsList;
    const currentUserDetails = this.props.currentUserDetails;
    const advisorDetail = this.props.advisorDetail;

    /* loop through meetings and add client display_name to meeting */

    meetingsList.forEach((meetingItem, meetingIndex) => {
      meetingsList[meetingIndex]['client_name'] = [];
      const selectedList = meetingItem['client_list'];

      clientsList.forEach((clientItem, clientIndex) => {
        let filteredSelectedList = selectedList.find(
          (item) => item === clientItem.profile_id
        );

        if (filteredSelectedList) {
          meetingsList[meetingIndex].client_name.push(clientItem.display_name);
        }
      });
    });

    const columns = [
      {
        dataField: 'date',
        text: 'Date',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'datetime',
        text: 'Date/Time',
        sort: true,
        isDummyField: true,
        formatter: (cell, row) => {
          return dayjs(row.date + ' ' + row.time).format('dd D/M   h:mm a');
        },
      },
      {
        dataField: 'time',
        text: '',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'meeting_title',
        text: 'Topic',
        sort: true,
      },

      {
        dataField: 'client_name',
        text: 'Client(s)',
        sort: true,
      },

      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedAdvisorMeetingDetail: row,
          isNewAdvisorMeeting: false,
          active: true,
          isAdvisorMeetingDetailFormOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const advisorMeetingsList = this.props.advisorMeetingsList;

    /* let filteredAdvisorMeetingsList = advisorMeetingsList.filter(
      (meetingItem) =>
        meetingItem.referrer_id === this.props.currentUser.id ||
        meetingItem.status === 'Available' ||
        meetingItem.status === 'Reserved'
    );  */

    return (
      <div>
        <div className='d-flex  mt-3'>
          <h3>Advisor Meetings</h3>
          <div
            onClick={() => this.handleAddNewAdvisorMeetingButton()}
            className='btn btn-outline-dark ml-auto mb-2'>
            <span>Add New Meeting</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no meetings to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={meetingsList || []}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='agent-meeting-detail-pane'
          overlayClassName='pane-agent-meeting-sliding-pane'
          isOpen={this.state.isAdvisorMeetingDetailFormOpen}
          title='Advisor Meeting Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isAdvisorMeetingDetailFormOpen: false });
          }}>
          <AdvisorMeetingDetailForm
            advisorDetail={advisorDetail}
            clientsList={clientsList}
            currentUserDetails={currentUserDetails}
            selectedAdvisorMeetingDetail={
              this.state.selectedAdvisorMeetingDetail
            }
            isNewAdvisorMeeting={this.state.isNewAdvisorMeeting}
            onAdvisorMeetingSaveDeleteButtonClicked={() =>
              this.handleAdvisorMeetingSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default AdvisorMeetings;
