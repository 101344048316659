import React, { useState, Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import SwitchComponents from '../helpers/switchComponents';

import StrategySummary from '../components/strategy/StrategySummary';
import Goals from '../components/strategy/goals/Goals';
import Activities from '../components/strategy/activities/Activities';
import BusinessDetails from '../components/strategy/business/BusinessDetails';
import TargetsMain from '../components/strategy/targets/TargetsMain';
import Marketing from '../components/strategy/marketing/Marketing';
import Sales from '../components/strategy/sales/Sales';
import Service from '../components/strategy/service/Service';
import Risk from '../components/strategy/risk/Risk';

import { Nav, NavDropdown, Navbar, Form, FormControl } from 'react-bootstrap';

// get our fontawesome imports

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faListAlt,
  faTrophy,
  faRunning,
  faUserTie,
  faBullseye,
  faFunnelDollar,
  faProjectDiagram,
  faComments,
  faClock,
  faDollarSign,
  faUserClock,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

import { auth } from '../services/firebase';
import { db } from '../services/firebase';
import Processes from '../components/strategy/processes/Processes';
import Communication from '../components/strategy/communication/Communication';
import TimeManagement from '../components/strategy/time/TimeManagement';
import Finance from '../components/strategy/finance/Finance';

class BizStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,

      sideBarIsCollapsed: false,
      activeComponent: 'business-summary',
      loadingBizStrategy: true,
      loadingGoalsList: true,
      dashboardId: '',
      businessId: '',
      businessDetail: [],
      expensesList: [],
      totalExpenses: 0,
      loadingExpensesList: true,
      productsServicesList: [],
      totalTargetGrossProfit: 0,
      loadingProductsServicesList: true,
      linkedEntityKeys: [],
      loadingBusinesses: true,
      totalGoals: 0,
      totalActivities: 0,
      linkToOptions: [],
      subMenuOpen: {
        'business-details': false,
        'targets-main': false,
      },
    };
    this.bizStrategyAreaRef = React.createRef();
    //   this.handleSidebarHeaderClick = this.handleSidebarHeaderClick.bind(this);
    //   this.handleDashboardClick = this.handleDashboardClick.bind(this);
    //   this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  async componentDidMount() {
    const pathArray = window.location.pathname.split('/');
    let businessId = pathArray[3];
    let dashboardId = pathArray[2];

    let goalsList = [];
    let linkedEntityKeys = [];
    let activitiesList = [];
    let expensesList = [];
    let productsServicesList = [];
    let totalGoals = 0;
    let totalActivities = 0;
    let linkToOptions = [];
    let marketingDetail = [];

    try {
      await db
        .collection('dashboards/' + dashboardId + '/businesses')
        .onSnapshot((snapshot) => {
          let businessesList = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data['key'] = doc.id;
            businessesList.push(data);

            if (data.status === 'existing') {
              let linkTo = [];
              linkTo['key'] = doc.id;
              linkTo['name'] = data.business_name;
              linkToOptions.push(linkTo);
            }
          });
          this.setState({ businessesList });
        })
        .then(this.setState({ loadingBusinesses: false }))
        .catch(function (next) {
          console.log(next);
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }

    try {
      db.collection('dashboards/' + dashboardId + '/marketing')
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            data['key'] = doc.id;
            marketingDetail = data;
            linkedEntityKeys['marketing_key'] = doc.id;
          });
          this.setState({ marketingDetail });
        })
        .then()
        .catch(function (next) {
          //console.log(next);
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }

    try {
      db.collection('dashboards/' + dashboardId + '/personal')
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            linkedEntityKeys['personal_key'] = doc.id;
            let linkTo = [];

            linkTo['key'] = doc.id;
            linkTo['name'] = 'Personal';
            linkToOptions.push(linkTo);
          });
        })
        .then()
        .catch(function (next) {
          //console.log(next);
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }

    if (businessId && dashboardId) {
      await db
        .collection('dashboards')
        .doc(dashboardId)
        .collection('businesses')
        .doc(businessId)
        .onSnapshot((doc) => {
          let data = doc.data();

          data['dashboard_id'] = dashboardId;
          data['business_id'] = businessId;

          linkedEntityKeys['dashboard_id'] = dashboardId;
          linkedEntityKeys['linked_entity_id'] = businessId;
          linkedEntityKeys['source'] = 'biz_strategy';

          this.setState({
            linkToOptions,
            linkedEntityKeys,
            businessDetail: data,
          });
        });

      db.collection('dashboards/' + dashboardId + '/goals')
        .where('deleted', '==', false)
        .where('completed', '==', false)
        .where('linked_entity_id', '==', businessId)
        .onSnapshot((snapshot) => {
          goalsList = [];

          snapshot.forEach((doc) => {
            const data = doc.data();
            data['key'] = doc.id;
            goalsList.push(data);
          });
          totalGoals = goalsList.length;
          this.setState({ goalsList, totalGoals });
        });

      db.collection('dashboards/' + dashboardId + '/activities')
        .where('deleted', '==', false)
        .where('linked_entity_id', '==', businessId)
        .onSnapshot((snapshot) => {
          activitiesList = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data['key'] = doc.id;

            if (data.linked_goal !== 'None') {
              let linkedGoal = goalsList.filter((goalItem) =>
                goalItem.key.includes(data.linked_goal)
              );
              if (linkedGoal[0].short_name) {
                data['linked_goal_name'] = linkedGoal[0].short_name;
              }
            } else {
              data['linked_goal_name'] = 'No linked goal';
            }

            activitiesList.push(data);
          });
          totalActivities = activitiesList.length;
          this.setState({ activitiesList, totalActivities });
        });

      db.collection('dashboards')
        .doc(dashboardId)
        .collection('businesses')
        .doc(businessId)
        .collection('expenses')
        .onSnapshot((snapshot) => {
          expensesList = [];
          let totalExpenses = 0;
          snapshot.forEach((doc) => {
            let data = doc.data();
            data['key'] = doc.id;
            expensesList.push(data);
            totalExpenses += data.amount * data.num_times;
          });
          this.setState({ expensesList, totalExpenses });
        });

      db.collection('dashboards')
        .doc(dashboardId)
        .collection('businesses')
        .doc(businessId)
        .collection('products_services')
        .onSnapshot((snapshot) => {
          productsServicesList = [];
          let totalTargetGrossProfit = 0;
          snapshot.forEach((doc) => {
            let data = doc.data();
            data['key'] = doc.id;
            productsServicesList.push(data);

            totalTargetGrossProfit +=
              parseFloat(data.target_amount) *
              parseFloat(data.target_frequency) *
              ((parseFloat(data.sell_price) - parseFloat(data.cost_price)) *
                parseFloat(data.num_times));
          });
          this.rebuildProductsServicesList(
            productsServicesList,
            totalTargetGrossProfit
          );
        });

      this.setState({
        activeComponent: 'business-summary',
        dashboardId,
        businessId,
        goalsList,
      });
    } else {
      window.location = '/dashboard';
    }
  }

  handleSidebarHeaderClick = () => {
    const sideBarIsCollapsed = !this.state.sideBarIsCollapsed;
    this.setState({ sideBarIsCollapsed });
  };

  handleDashboardClick = () => {
    window.location = '/dashboard/' + this.state.dashboardId;
  };

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ activeComponent: selectedComponent });
  };

  handleSubMenuMouseEnterLeave = (sectionName, isSubMenuOpen) => {
    let subMenuOpen = this.state.subMenuOpen;
    subMenuOpen[sectionName] = isSubMenuOpen;
    this.setState({ subMenuOpen });
  };

  rebuildProductsServicesList = (
    productsServicesList,
    totalTargetGrossProfit
  ) => {
    let tempProductsServicesList = [];

    productsServicesList.map((productService) => {
      const sellPrice = parseFloat(productService['sell_price']);
      const costPrice = parseFloat(productService['cost_price']);
      const numTimes = parseFloat(productService['num_times']);
      const targetAmount = parseFloat(productService['target_amount']);
      const targetFrequency = parseFloat(productService['target_frequency']);
      const numHours = parseFloat(productService['num_hours']);

      const grossProfitNeeded =
        parseFloat(this.state.totalExpenses ? this.state.totalExpenses : 0) +
        parseFloat(
          this.state.businessDetail.pay_yourself_first
            ? this.state.businessDetail.pay_yourself_first
            : 0
        );

      const grossProfit = (sellPrice - costPrice) * numTimes;
      const breakEven = grossProfitNeeded / grossProfit;

      const totalTarget = targetAmount * targetFrequency;

      const breakevenHours =
        (numHours * numTimes * grossProfitNeeded) /
        ((sellPrice - costPrice) * numTimes);
      const targetHours = targetAmount * targetFrequency * numHours * numTimes;

      productService['daily_be'] = breakEven / 365;
      productService['weekly_be'] = breakEven / 52;
      productService['fortnightly_be'] = breakEven / 26;
      productService['monthly_be'] = breakEven / 12;
      productService['quarterly_be'] = breakEven / 4;
      productService['yearly_be'] = breakEven;

      productService['daily_tbe'] = breakEven / 365;
      productService['weekly_tbe'] = breakEven / 52;
      productService['fortnightly_tbe'] = breakEven / 26;
      productService['monthly_tbe'] = breakEven / 12;
      productService['quarterly_tbe'] = breakEven / 4;
      productService['yearly_tbe'] = breakEven;

      productService['daily_gp'] = grossProfit / 365;
      productService['weekly_gp'] = grossProfit / 52;
      productService['fortnightly_gp'] = grossProfit / 26;
      productService['monthly_gp'] = grossProfit / 12;
      productService['quarterly_gp'] = grossProfit / 4;
      productService['yearly_gp'] = grossProfit;

      productService['daily_tgp'] = (totalTarget * grossProfit) / 365;
      productService['weekly_tgp'] = (totalTarget * grossProfit) / 52;
      productService['fortnightly_tgp'] = (totalTarget * grossProfit) / 26;
      productService['monthly_tgp'] = (totalTarget * grossProfit) / 12;
      productService['quarterly_tgp'] = (totalTarget * grossProfit) / 4;
      productService['yearly_tgp'] = totalTarget * grossProfit;

      productService['daily_behrs'] = breakevenHours / 365;
      productService['weekly_behrs'] = breakevenHours / 52;
      productService['fortnightly_behrs'] = breakevenHours / 26;
      productService['monthly_behrs'] = breakevenHours / 12;
      productService['quarterly_behrs'] = breakevenHours / 4;
      productService['yearly_behrs'] = breakevenHours;

      productService['daily_targethrs'] = targetHours / 365;
      productService['weekly_targethrs'] = targetHours / 52;
      productService['fortnightly_targethrs'] = targetHours / 26;
      productService['monthly_targethrs'] = targetHours / 12;
      productService['quarterly_targethrs'] = targetHours / 4;
      productService['yearly_targethrs'] = targetHours;

      tempProductsServicesList.push(productService);

      return true;
    });

    this.setState({
      productsServicesList: tempProductsServicesList,
      totalTargetGrossProfit,
    });
  };

  render() {
    return (
      <div className=''>
        <Header />
        <div className='d-flex flex-row'>
          <div
            className='ml-auto'
            style={{
              zIndex: '1',
              minHeight: 'calc(100vh - 120px)',
              width: 'calc(100vw-300px)',
            }}>
            <ProSidebar
              className='prosidebarstyle'
              collapsed={this.state.sideBarIsCollapsed}>
              <SidebarHeader
                className='text-center sidebar-header-arrow'
                onClick={this.handleSidebarHeaderClick}>
                {this.state.sideBarIsCollapsed ? (
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                )}
              </SidebarHeader>
              <SidebarContent className=''>
                <Menu popperArrow='true' iconShape='circle'>
                  <MenuItem
                    className=''
                    icon={<FontAwesomeIcon icon={faHome} />}
                    onClick={() => this.handleDashboardClick()}>
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faListAlt} />}
                    onClick={() =>
                      this.handleSwitchComponent('business-summary')
                    }>
                    Strategy Summary
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faTrophy} />}
                    onClick={() =>
                      this.handleSwitchComponent('business-goals')
                    }>
                    Goals
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faRunning} />}
                    onClick={() =>
                      this.handleSwitchComponent('business-activities')
                    }>
                    Activities
                  </MenuItem>
                </Menu>

                <Menu popperArrow='true' iconShape='circle'>
                  <SubMenu
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'business-details',
                        true
                      )
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'business-details',
                        false
                      )
                    }
                    open={this.state.subMenuOpen['business-details']}
                    onClick={() =>
                      this.handleSwitchComponent('business-details')
                    }
                    icon={<FontAwesomeIcon icon={faUserTie} />}
                    title={<strong>Priority 1</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('business-details')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['business-details'] ? (
                        <div className='pt-1'>
                          <strong>Priority 1:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Purpose/Why
                        <br />
                        Exit Strategy
                        <br />
                        Pay Yourself First
                      </div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['targets-main']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave('targets-main', true)
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave('targets-main', false)
                    }
                    onClick={() => this.handleSwitchComponent('targets-main')}
                    icon={<FontAwesomeIcon icon={faBullseye} />}
                    title={<strong>Priority 2</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('targets-main')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['targets-main'] ? (
                        <div className='pt-1'>
                          <strong>Priority 2:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Targets
                        <br />
                        Expenses
                        <br />
                        Products/Services
                      </div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['marketing-main']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave('marketing-main', true)
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave('marketing-main', false)
                    }
                    icon={<FontAwesomeIcon icon={faFunnelDollar} />}
                    title={<strong>Priority 3</strong>}>
                    {this.state.sideBarIsCollapsed &&
                    this.state.subMenuOpen['marketing-main'] ? (
                      <div className='pt-1'>
                        <strong>Priority 3:</strong>
                      </div>
                    ) : (
                      ''
                    )}
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('marketing-main')
                      }>
                      <div className='small pl-2'>Marketing</div>
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleSwitchComponent('sales-main')}>
                      <div className='small pl-2'>Sales</div>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('service-main')
                      }>
                      <div className='small pl-2'>Service</div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['processes-systems']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'processes-systems',
                        true
                      )
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'processes-systems',
                        false
                      )
                    }
                    onClick={() =>
                      this.handleSwitchComponent('processes-systems')
                    }
                    icon={<FontAwesomeIcon icon={faProjectDiagram} />}
                    title={<strong>Priority 4</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('processes-systems')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['processes-systems'] ? (
                        <div className='pt-1'>
                          <strong>Priority 4:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Processes
                        <br />
                        Systems
                      </div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['risk-management']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave('risk-management', true)
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'risk-management',
                        false
                      )
                    }
                    onClick={() =>
                      this.handleSwitchComponent('risk-management')
                    }
                    icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                    title={<strong>Priority 5</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('risk-management')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['risk-management'] ? (
                        <div className='pt-1'>
                          <strong>Priority 5:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Risk Management
                        <br />
                        Compliance
                      </div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['time-management']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave('time-management', true)
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave(
                        'time-management',
                        false
                      )
                    }
                    onClick={() =>
                      this.handleSwitchComponent('time-management')
                    }
                    icon={<FontAwesomeIcon icon={faUserClock} />}
                    title={<strong>Priority 6</strong>}>
                    <MenuItem
                      onClick={() =>
                        this.handleSwitchComponent('time-management')
                      }>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['time-management'] ? (
                        <div className='pt-1'>
                          <strong>Priority 6:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>Time Management</div>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    open={this.state.subMenuOpen['finance']}
                    onMouseEnter={() =>
                      this.handleSubMenuMouseEnterLeave('finance', true)
                    }
                    onMouseLeave={() =>
                      this.handleSubMenuMouseEnterLeave('finance', false)
                    }
                    onClick={() => this.handleSwitchComponent('finance')}
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    title={<strong>Priority 7</strong>}>
                    <MenuItem
                      onClick={() => this.handleSwitchComponent('finance')}>
                      {this.state.sideBarIsCollapsed &&
                      this.state.subMenuOpen['finance'] ? (
                        <div className='pt-1'>
                          <strong>Priority 7:</strong>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='small pl-2'>
                        Cashflow
                        <br />
                        Finance
                      </div>
                    </MenuItem>
                  </SubMenu>

                  <hr />
                </Menu>
              </SidebarContent>
              <div className=''>&nbsp;</div>
            </ProSidebar>
          </div>
          <div className='col text-left'>
            <h3 className='mt-3'>
              Business Strategy for{' '}
              {this.state.businessDetail ? (
                <strong>{this.state.businessDetail['business_name']}</strong>
              ) : (
                ''
              )}
            </h3>
            <hr />
            {/* SwitchComponents need more than 1 child element */}
            <SwitchComponents active={this.state.activeComponent}>
              <StrategySummary
                name='business-summary'
                businessDetail={this.state.businessDetail}
                linkedEntityKeys={this.state.linkedEntityKeys}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                totalGoals={this.state.totalGoals}
                totalActivities={this.state.totalActivities}
              />
              <Goals
                isBusinessPersonalColHidden={true}
                businessesList={this.state.businessesList}
                goalsList={this.state.goalsList}
                linkedEntityKeys={this.state.linkedEntityKeys}
                linkToOptions={this.state.linkToOptions}
                name='business-goals'
              />
              <Activities
                isBusinessPersonalColHidden={true}
                businessesList={this.state.businessesList}
                activitiesList={this.state.activitiesList}
                goalsList={this.state.goalsList}
                linkedEntityKeys={this.state.linkedEntityKeys}
                linkToOptions={this.state.linkToOptions}
                name='business-activities'
              />
              <BusinessDetails
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='business-details'
                businessDetail={this.state.businessDetail}
              />
              <TargetsMain
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='targets-main'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <Marketing
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='marketing-main'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
                marketingDetail={this.state.marketingDetail}
              />

              <Sales
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='sales-main'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <Service
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='service-main'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <Processes
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='processes-systems'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <Risk
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='risk-management'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <TimeManagement
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='time-management'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <Finance
                linkedEntityKeys={this.state.linkedEntityKeys}
                name='finance'
                businessDetail={this.state.businessDetail}
                expensesList={this.state.expensesList}
                totalExpenses={this.state.totalExpenses}
                totalTargetGrossProfit={this.state.totalTargetGrossProfit}
                productsServicesList={this.state.productsServicesList}
              />

              <div name='blank'>&nbsp;</div>
            </SwitchComponents>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default BizStrategy;
