import firebase from 'firebase';

var firebaseConfig = {
  apiKey: 'AIzaSyDMiM8AfTCioE1Zxtbf06LH5ZsNYZI8wCo',
  authDomain: 'biz-strategy.firebaseapp.com',
  databaseURL: 'https://biz-strategy.firebaseio.com',
  projectId: 'biz-strategy',
  storageBucket: 'biz-strategy.appspot.com',
  messagingSenderId: '497819795229',
  appId: '1:497819795229:web:e8984f59abf5d4a146ebf4',
  measurementId: 'G-05E3Q06GGP',
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth;
export const db = firebase.firestore();
